import { createSlice } from '@reduxjs/toolkit';
import localForage from 'localforage';
import { setFulfilledThunkActionStatus, setThunkActionStatus } from '../utils';
import { saveUserHelp } from './userHelp.actions';
import { UserHelpState } from './userHelp.types';

const initialState: UserHelpState = {
  userHelp: null,
};

const userHelpSlice = createSlice({
  name: 'userHelp',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    setThunkActionStatus(builder, saveUserHelp);
    builder.addCase(saveUserHelp.fulfilled, (state, action) => {
      setFulfilledThunkActionStatus(state, action);
      state.userHelp = action.payload;
    });
  },
});

const { reducer: userHelpReducer, actions: userHelpActions } = userHelpSlice;

const userHelpPersistConfig = {
  key: 'userHelp',
  storage: localForage,
  blacklist: [],
};

export { userHelpPersistConfig, userHelpReducer, userHelpActions };
