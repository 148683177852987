import { Config } from '@ecdlink/core';
import { api } from '../axios.helper';
import { UserHelp, MutationSaveUserHelpArgs } from '@ecdlink/graphql';

class UserHelpService {
  _accessToken: string | null;

  constructor(accessToken: string | null) {
    this._accessToken = accessToken;
  }

  async saveUserHelp(input: MutationSaveUserHelpArgs): Promise<UserHelp> {
    const apiInstance = api(Config.graphQlApi, this._accessToken!);
    const response = await apiInstance.post<{
      data: { saveUserHelp: UserHelp };
      errors?: {};
    }>(``, {
      query: `
        mutation SaveUserHelp($userHelp: UserHelpInput!, $screenshotBase64String: String, $fileName: String, $fileType: FileTypeEnum!) {
          saveUserHelp(userHelp: $userHelp, screenshotBase64String: $screenshotBase64String, fileName: $fileName, fileType: $fileType) {
            id
            insertedDate
            updatedDate
            updatedBy
            tenantId
            userId
            subject
            description
            contactPreference
            cellNumber
            email
            isLoggedIn
            isActive
            url
          }
        }
      `,
      variables: {
        userHelp: input.userHelp,
        fileName: input.fileName,
        fileType: input.fileType,
        screenshotBase64String: input.screenshotBase64String,
      },
    });
    return response.data.data.saveUserHelp;
  }
}

export default UserHelpService;
