import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import ROUTES from '@/routes/routes';
import { useAppDispatch } from '@/store';
import { authSelectors } from '@/store/auth';
import { FileTypeEnum } from '@ecdlink/graphql';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { userHelpThunkActions } from '@/store/userHelp';
import { useOnlineStatus } from '@/hooks/useOnlineStatus';
import { mapUserHelpDto } from '@/utils/user/user-help.utils';
import { base64StringCleanUp } from '@/utils/common/base64StringCleanUp.utils';
import {
  BannerWrapper,
  Button,
  Dialog,
  DialogPosition,
  FormInput,
  ImageInput,
  Radio,
  Typography,
} from '@ecdlink/ui';
import {
  HelpFormModel,
  HelpValidationSchema,
  UserHelpFormModel,
  initialHelpFormValues,
} from '@/schemas/help/help';
import { subjectOptions } from './options';
import { PhotoPrompt } from '@/components/photo-prompt/photo-prompt';

export const UserHelp: React.FC = () => {
  const {
    register: helpRegister,
    setValue: setHelpFormValue,
    getValues: getHelpFormValue,
    formState: helpFormState,
    handleSubmit,
    control,
    trigger,
  } = useForm({
    resolver: yupResolver(HelpValidationSchema),
    defaultValues: initialHelpFormValues.userHelp,
    mode: 'onChange',
  });
  const appDispatch = useAppDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);
  const { isValid, errors } = helpFormState;
  const userAuth = useSelector(authSelectors.getAuthUser);
  const [screenshotBase64String, setScreenshotBase64String] =
    useState<string>('');
  const [photoActionBarVisible, setPhotoActionBarVisible] =
    useState<boolean>(false);

  const maxCharactersInDescription = 520;
  const { isOnline } = useOnlineStatus();
  const history = useHistory();
  const imageExtensions = ['svg', 'png', 'PNG', 'jpg', 'JPG', 'jpeg'];
  const contactPreference = [
    {
      id: 1,
      value: 'SMS',
    },
    {
      id: 2,
      value: 'Email',
    },
  ];
  // eslint-disable-next-line no-empty-pattern
  const { Subject } = useWatch({ control });

  useEffect(() => {
    if (userAuth) {
      setHelpFormValue('UserId', userAuth.id);
      setHelpFormValue('IsLoggedIn', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setScreenshotUrl = (imageUrl: string) => {
    setScreenshotBase64String(imageUrl);
    trigger();
    setPhotoActionBarVisible(false);
  };

  const toggleContactPreference = (value: string) => {
    setHelpFormValue('PreferSMS', value === 'SMS' ? true : false);
    setHelpFormValue('ContactPreference', value);
    setHelpFormValue('ContactPreferenceValue', '');
  };

  const submitForm = async () => {
    setLoading(() => true);
    if (getHelpFormValue('ContactPreference') === 'SMS')
      setHelpFormValue(
        'CellNumber',
        getHelpFormValue('ContactPreferenceValue')
      );
    if (getHelpFormValue('ContactPreference') === 'Email')
      setHelpFormValue('Email', getHelpFormValue('ContactPreferenceValue'));
    let helpForm: UserHelpFormModel = {
      userHelp: getHelpFormValue(),
      fileType: FileTypeEnum.Unknown,
      fileName: '',
      screenshotBase64String: '',
    };
    if (screenshotBase64String !== '') {
      helpForm = {
        userHelp: getHelpFormValue(),
        fileType: FileTypeEnum.ContentImage,
        fileName: `${Subject}_issue_screenshot.png`,
        screenshotBase64String: await base64StringCleanUp(
          screenshotBase64String
        ),
      };
    }
    await appDispatch(
      userHelpThunkActions.saveUserHelp(mapUserHelpDto(helpForm!))
    ).then(() => {
      history.push(ROUTES.ROOT);
    });
  };

  return (
    <BannerWrapper
      showBackground={false}
      size={'normal'}
      renderBorder={true}
      title={'Get Help'}
      subTitle={'Fill in the form to get help'}
      color={'primary'}
      onBack={() => history.push(ROUTES.DASHBOARD)}
      displayOffline={!isOnline}
    >
      <div className="flex flex-col gap-4 p-4">
        <form>
          <Typography
            type="h4"
            className="text-textDark mb-2 block font-medium"
            text={'What do you need help with?'}
          />
          <fieldset className="flex flex-col gap-2">
            {subjectOptions.map((item, index) => (
              <Radio
                className="mb-2"
                variant="slim"
                key={index}
                description={item}
                value={item}
                required={true}
                checked={getHelpFormValue().Subject === item}
                name="Subject"
                onChange={(e) => {
                  const value = e.target.value;
                  setHelpFormValue('Subject', value);
                }}
              />
            ))}
          </fieldset>
          <FormInput<HelpFormModel>
            label={'Please describe the problem'}
            nameProp={'Description'}
            register={helpRegister}
            error={errors['Description']}
            placeholder="Add a description..."
            textInputType="textarea"
            className="mb-5"
            maxCharacters={maxCharactersInDescription}
          />
          <ImageInput<HelpFormModel>
            acceptedFormats={imageExtensions}
            label="Add a screenshot of the problem"
            subLabel={'Optional'}
            nameProp="Url"
            icon="CameraIcon"
            iconContainerColor={'tertiary'}
            className="py-4"
            currentImageString={screenshotBase64String}
            register={helpRegister}
            overrideOnClick={() => setPhotoActionBarVisible(true)}
            onValueChange={(imageString: string) => {
              setScreenshotBase64String(() => imageString);
              trigger();
            }}
          />
          <Typography
            type="h4"
            className="text-textDark mb-2 block font-medium"
            text={'How would you like us to contact you?'}
          />
          <fieldset className="flex w-full flex-row gap-2">
            {contactPreference.map((item, index) => (
              <Button
                key={index}
                className="mb-4 w-full rounded-2xl"
                type="filled"
                color={
                  getHelpFormValue().ContactPreference === item.value
                    ? 'tertiary'
                    : 'tertiaryAccent2'
                }
                textColor={
                  getHelpFormValue().ContactPreference === item.value
                    ? 'white'
                    : 'tertiary'
                }
                text={item.value}
                onClick={() => toggleContactPreference(item.value)}
              />
            ))}
          </fieldset>
          <FormInput<HelpFormModel>
            visible={true}
            label={
              getHelpFormValue().ContactPreference === 'SMS'
                ? 'Enter your phone number'
                : 'Enter your email address'
            }
            nameProp="ContactPreferenceValue"
            register={helpRegister}
            error={errors['ContactPreferenceValue']}
            placeholder={
              getHelpFormValue().ContactPreference === 'SMS'
                ? 'Enter your phone number'
                : 'Enter your email address'
            }
            className="mb-5"
          />
          <Button
            id="gtm-save-help-form"
            className="mb-4 w-full"
            type="filled"
            icon={'SaveIcon'}
            isLoading={isLoading}
            color="secondaryAccent1"
            onClick={handleSubmit(submitForm)}
            disabled={!isValid}
          >
            <Typography type="help" color="white" text={'Save'}></Typography>
          </Button>
        </form>
      </div>
      <Dialog
        visible={photoActionBarVisible}
        position={DialogPosition.Middle}
        stretch
      >
        <PhotoPrompt
          title="Get Help form"
          onClose={() => setPhotoActionBarVisible(false)}
          onAction={(imageUrl: string) => setScreenshotUrl(imageUrl)}
          onDelete={
            screenshotBase64String !== ''
              ? () => {
                  setHelpFormValue('Url', '');
                  setScreenshotBase64String('');
                  setPhotoActionBarVisible(false);
                }
              : undefined
          }
        ></PhotoPrompt>
      </Dialog>
    </BannerWrapper>
  );
};
