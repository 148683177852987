export const pResetContainer = 'p-4';
export const buttonSpace = 'mt-3';
export const bigSpace = 'mt-1';
export const errorDisplay = 'mb-3';
export const submitButton = 'w-full';
export const buttonIcon = 'h-5 w-5 text-primary mr-2';
export const goBackButton = 'mt-6 mb-2 w-full';
export const container = 'min-h-full bg-white flex flex-1 flex-col';
export const getHelpContainer =
  'flex flex-1 flex-row justify-start items-center mt-6';
