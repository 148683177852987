export const dialogContent = 'h-full w-screen bg-uiBg overflow-y-hidden ';
export const searchTextWrapper =
  'flex flex-row items-center justify-between px-4 h-16 bg-primary';
export const iconFill = 'w-6 text-white cursor-pointer ';
export const searchInput =
  'outline-none block w-full sm:text-sm placeholder-primaryAccent2 font-h1 py-2 px-4 bg-primary';
export const dialogContentStackedList = 'pb-180 ';
export const quickSearchWrapper =
  'w-full flex flex-row items-center px-5 py-1 bg-uiBg overflow-x-auto border-b border-t border-uiLight';
export const searchIconWrapper =
  'inline-flex items-center p-2 cursor-pointer rounded-full round bg-primary border-2 border-primary mr-5';
