import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  MutationSaveUserHelpArgs,
  UserHelp,
} from '../../../../../packages/graphql/src/graphql/generatedGraphql';
import { RootState, ThunkApiType } from '../types';
import { UserHelpService } from '@/services/UserHelp';

export const UserHelpActions = {
  SAVE_USER_HELP: 'saveUserHelp',
  USER_HELP_SCREENSHOT_UPLOAD: 'userHelpScreenshotUpload',
};

export const saveUserHelp = createAsyncThunk<
  UserHelp,
  MutationSaveUserHelpArgs,
  ThunkApiType<RootState>
>(
  UserHelpActions.SAVE_USER_HELP,
  async (input, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
    } = getState();
    try {
      return await new UserHelpService(
        userAuth?.auth_token ?? null
      ).saveUserHelp(input);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
