export const wrapper =
  'w-full rounded-lg flex flex-col justify-between items-start';

export const contentWrapper = 'w-full p-4';
export const spaceRight = 'mr-1';
export const buttonIcon = 'w-5 h-5 text-white mr-1';
export const spaceTopBig = 'mt-4';
export const spaceTopSmall = 'mt-1';
export const spaceTopMedium = 'mt-1';
export const headerBar = 'flex flex-row flex-nowrap pb-4 items-center';
export const levelContainer = 'flex flex-row flex-nowrap pt-2 items-center';
