import { ProvinceDto } from '@/../../../packages/core/lib';
import * as Yup from 'yup';

export interface SiteAddressDetailsModel {
  ward: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  provinceId: string;
  postalCode: string;
  areaClassification: string;
  areaSubClassification: string;
  area: string;
  municipality: string;
}

export const initialSiteAddressValues: SiteAddressDetailsModel = {
  ward: '',
  area: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  provinceId: '',
  postalCode: '',
  areaClassification: '',
  areaSubClassification: '',
  municipality: '',
};

export const SiteAddressDetailsSchema = Yup.object().shape({
  addressLine1: Yup.string().required('Address line 1 is required'),
  addressLine2: Yup.string().required('Address line 2 required'),
  addressLine3: Yup.string().required('City is required'),
  area: Yup.string().required('Area classification is required'),
  municipality: Yup.string().required('Municipality is required'),
  provinceId: Yup.string().required('Please select a province'),
  ward: Yup.string()
    .matches(/^\d{2,4}$/, 'ward must be between 2 and 4 digits')
    .required('ward is required'),
  postalCode: Yup.string()
    .matches(/^\d{4}$/, 'postalCode must be exactly 4 digits')
    .required('postalCode is required'),
});
