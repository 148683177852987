import {
  ContentConsentTypeEnum,
  ProgrammeTypeDto,
  ClassroomDto,
  SiteAddressDto,
  ProvinceDto,
} from '@ecdlink/core';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Button,
  ButtonGroup,
  Typography,
  ButtonGroupTypes,
  renderIcon,
  BannerWrapper,
  Checkbox,
  ImageInput,
  Dialog,
  DialogPosition,
  FormInput,
  Dropdown,
  classNames,
} from '@ecdlink/ui';
import { useCallback, useEffect, useState } from 'react';
import { useForm, useFormState, useWatch, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { staticDataSelectors } from '@store/static-data';
import * as styles from './programme-details.styles';
import { yesNoOptions } from './programme-details.types';
import { useOnlineStatus } from '@/hooks/useOnlineStatus';
import Article from '@/components/article/article';
import {
  ProgrammeDetailsModel,
  ProgrammeDetailsSchema,
} from '@/schemas/trainee/programme-details';
import { PhotoPrompt } from '@/components/photo-prompt/photo-prompt';
import {
  SmartSpaceChecklistProps,
  SmartSpaceChecklistStepsSteps,
} from '../../smart-space-checklist.types';
import { traineeSelectors } from '@/store/trainee';
import { AddressMap } from '../map/map';
import tool_R4c_form from '@/assets/tool_R4c_form.pdf';
import tool_R4b_form from '@/assets/tool_R4b_form.pdf';
import tool_R4a_form from '@/assets/tool_R4a_form.pdf';
import { useAppDispatch } from '@/store';
import { newGuid } from '@utils/common/uuid.utils';
import {
  classroomsActions,
  classroomsSelectors,
  classroomsThunkActions,
} from '@/store/classroom';
import { cloneDeep } from 'lodash';

export const ProgrammeDetails: React.FC<SmartSpaceChecklistProps> = ({
  setSectionQuestions,
  setVisitSection,
  setActiveStep,
  onSubmitAndContinue,
  checklistVisitId,
}) => {
  const {
    getValues: getProgrammeFormValues,
    setValue: setProgrammeFormValue,
    register: programmeFormRegister,
    trigger: triggerR4bForm,
    control: programmeFormControl,
  } = useForm<ProgrammeDetailsModel>({
    resolver: yupResolver(ProgrammeDetailsSchema),
    shouldUnregister: true,
    mode: 'onChange',
  });
  const provinces = useSelector(staticDataSelectors.getProvinces);
  const { isOnline } = useOnlineStatus();
  const { isValid, errors } = useFormState({ control: programmeFormControl });
  const {
    haveTheTitleDeeds,
    ownTheProperty,
    unproclaimedLand,
    liveAtTheProperty,
  } = useWatch<ProgrammeDetailsModel>({
    control: programmeFormControl,
    defaultValue: {},
  });
  const [articleTitle, setArticleTitle] = useState<string>();
  const [presentArticle, setPresentArticle] = useState<boolean>(false);
  const [province, setProvince] = useState<ProvinceDto>();
  const [contentConsentTypeEnum, setContentConsentTypeEnum] =
    useState<ContentConsentTypeEnum>();
  const [r4bPhotoUrl, setR4bPhotoUrl] = useState<string>();
  const [photoActionBarVisible, setPhotoActionBarVisible] =
    useState<boolean>(false);
  const acceptedFormats = ['jpg, bmp'];
  const visitData = useSelector(
    traineeSelectors.getTraineeVisitData(checklistVisitId)
  );
  const [showMap, setShowMap] = useState(false);
  const [displayPhotoDeleteWarning, setDisplayPhotoDeleteWarning] =
    useState<boolean>(false);

  const classroom = useSelector(classroomsSelectors.getClassroom);
  const [editedAddress, setEditedAddress] = useState(
    classroom?.siteAddress?.addressLine1 || ''
  );

  const appDispatch = useAppDispatch();

  const [questions, setAnswers] = useState([
    {
      question:
        'I have read the SmartStarter Information Sheet on Child Protection and Confidentiality',
      answer: false,
    },
    {
      question: 'What is the name of your programme?',
      answer: '',
    },
    {
      question: ' What type of programme are you running or planning to run?',
      answer: '',
    },
    {
      question: 'Ward number',
      answer: '',
    },
    {
      question: 'Address line 1',
      answer: '',
    },
    {
      question: 'Address line 2',
      answer: '',
    },
    {
      question: 'City',
      answer: '',
    },
    {
      question: 'Province',
      answer: '',
    },
    {
      question: 'Postal Code',
      answer: '',
    },
    {
      question:
        'Do you own the property where you will run your SmartStart programme?',
      answer: '',
    },
    {
      question: 'Do you have the Title Deeds for the property?',
      answer: '',
    },
    {
      question: 'Is the property on un-proclaimed land?',
      answer: '',
    },
    {
      question: 'Take a photo of a certified copy of the Title Deeds',
      answer: '',
    },
    {
      question:
        'Take a photo of the filled and signed R4c Affidavit - Property Ownership',
      answer: '',
    },
    {
      question: 'Do you live at the property?',
      answer: '',
    },
    {
      question:
        'Take a photo of the filled and signed R4a form - Agreement for premises to be uesd for early learning programme.',
      answer: '',
    },
    {
      question:
        'Take a photo of the filled and signed R4b form - Confirmation of Lease.',
      answer: '',
    },
    {
      question: 'Area Classification',
      answer: '',
    },
    {
      question: 'Area Sub Classification',
      answer: '',
    },
    {
      question: 'Area',
      answer: '',
    },
    {
      question: 'Municipality',
      answer: '',
    },
  ]);
  const visitSection = 'Programme details';

  const completedItems = visitData
    ?.filter((item) => item?.visitSection === visitSection)
    .filter(
      (item) =>
        item?.questionAnswer === 'true' ||
        (item?.questionAnswer !== ' ' &&
          item?.questionAnswer !== 'false' &&
          item?.questionAnswer !== '')
    );

  const disableSection = completedItems?.length
    ? completedItems?.length >= 6
    : false;

  const checkedquestion = (question: string) => {
    const isChecked = visitData?.find((item) => item?.question === question);
    return isChecked;
  };

  const programData = useSelector(staticDataSelectors.getProgrammeTypes);

  const displayArticle = async (key: ContentConsentTypeEnum, title: string) => {
    setContentConsentTypeEnum(key);
    setPresentArticle(true);
    setArticleTitle(title);
  };

  const onOptionSelected = useCallback(
    (value, index) => {
      const currentQuestion = questions[index];

      const updatedQuestions = questions.map((question) => {
        if (question.question === currentQuestion.question) {
          return {
            ...question,
            answer: value,
          };
        }
        return question;
      });

      setAnswers(updatedQuestions);
      setSectionQuestions?.([
        {
          visitSection,
          questions: updatedQuestions,
        },
      ]);
      triggerR4bForm();
    },
    [questions, setSectionQuestions]
  );

  const areaClassifications = ['Urban', 'Rural', 'Informal Settlement'];

  const areasSubClassifications = [
    'Metro',
    'City',
    'Town / Township',
    'Village',
    'Informal Settlement',
  ];

  const setPhotoUrl = (imageUrl: string) => {
    setProgrammeFormValue('r4bPhoto', imageUrl);

    setR4bPhotoUrl(imageUrl);
    triggerR4bForm();
    setPhotoActionBarVisible(false);
  };

  const deleteBirthDocumentPhoto = () => {
    setProgrammeFormValue('r4bPhoto', '');
    setR4bPhotoUrl('');
    setDisplayPhotoDeleteWarning(false);
  };

  const changeSmartSpaceCheckAddress = async () => {
    const classroomCopy = cloneDeep(classroom);
    if (classroomCopy) {
      const siteAddressId = classroomCopy.siteAddressId || newGuid();

      const siteAddress: SiteAddressDto = {
        id: siteAddressId,
        name: getProgrammeFormValues().programmeName || '',
        areaClassification: getProgrammeFormValues().areaClassification || '',
        areaSubClassification:
          getProgrammeFormValues().areaSubClassification || '',
        addressLine1: getProgrammeFormValues().addressLine1 || '',
        addressLine2: getProgrammeFormValues().addressLine2 || '',
        provinceId: getProgrammeFormValues().provinceId || '',
        municipality: getProgrammeFormValues().municipality || '',
        area: getProgrammeFormValues().area || '',
        addressLine3: getProgrammeFormValues().addressLine3 || '', // City
        postalCode: getProgrammeFormValues().postalCode || '',
        ward: getProgrammeFormValues().ward || '',
      };
      classroomCopy.siteAddress = siteAddress;
      classroomCopy.siteAddressId = siteAddressId;

      if (classroomCopy.siteAddress) {
        appDispatch(
          classroomsActions.updateClassroomSiteAddress(
            classroomCopy as ClassroomDto
          )
        );
        if (isOnline) {
          await appDispatch(
            classroomsThunkActions.upsertClassroomSiteAddress({})
          );
        }
      }
    }
  };

  useEffect(() => {
    if (checkedquestion(questions?.[0].question)?.questionAnswer) {
      setProgrammeFormValue('haveReadTheSmartStarterInformation', true);
    }

    if (checkedquestion(questions?.[1].question)?.questionAnswer) {
      setProgrammeFormValue(
        'programmeName',
        checkedquestion(questions?.[1].question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions?.[2].question)?.questionAnswer) {
      setProgrammeFormValue(
        'programmeType',
        checkedquestion(questions?.[2].question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions?.[3].question)?.questionAnswer) {
      setProgrammeFormValue(
        'ward',
        checkedquestion(questions?.[3].question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions[4].question)?.questionAnswer) {
      setProgrammeFormValue(
        'addressLine1',
        checkedquestion(questions[4].question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions[5].question)?.questionAnswer) {
      setProgrammeFormValue(
        'addressLine2',
        checkedquestion(questions[5].question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions[6].question)?.questionAnswer) {
      setProgrammeFormValue(
        'addressLine3',
        checkedquestion(questions[6].question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions[7].question)?.questionAnswer) {
      setProgrammeFormValue(
        'provinceId',
        checkedquestion(questions[7].question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions[8].question)?.questionAnswer) {
      setProgrammeFormValue(
        'postalCode',
        checkedquestion(questions[8].question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions?.[9].question)?.questionAnswer) {
      const answer =
        checkedquestion(questions?.[9].question)?.questionAnswer === 'false'
          ? false
          : true;
      setProgrammeFormValue('ownTheProperty', answer);
    }

    if (checkedquestion(questions?.[10]?.question)?.questionAnswer) {
      const answer =
        checkedquestion(questions?.[10].question)?.questionAnswer === 'false'
          ? false
          : true;
      setProgrammeFormValue('haveTheTitleDeeds', answer);
    }

    if (checkedquestion(questions?.[11]?.question)?.questionAnswer) {
      setProgrammeFormValue(
        'unproclaimedLand',
        Boolean(checkedquestion(questions?.[11].question)?.questionAnswer)
      );
    }

    if (checkedquestion(questions?.[12]?.question)?.questionAnswer) {
      setProgrammeFormValue(
        'r4bPhoto',
        checkedquestion(questions?.[12]?.question)?.questionAnswer!
      );

      setR4bPhotoUrl(
        checkedquestion(questions?.[12]?.question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions?.[13]?.question)?.questionAnswer) {
      setProgrammeFormValue(
        'r4bPhoto',
        checkedquestion(questions?.[13]?.question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions?.[14]?.question)?.questionAnswer) {
      const answer =
        checkedquestion(questions?.[14].question)?.questionAnswer === 'false'
          ? false
          : true;
      setProgrammeFormValue('liveAtTheProperty', answer);
    }

    if (checkedquestion(questions?.[15]?.question)?.questionAnswer) {
      setProgrammeFormValue(
        'r4bPhoto',
        checkedquestion(questions?.[15].question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions?.[16]?.question)?.questionAnswer) {
      setProgrammeFormValue(
        'r4bPhoto',
        checkedquestion(questions?.[16]?.question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions?.[17]?.question)?.questionAnswer) {
      setProgrammeFormValue(
        'areaClassification',
        checkedquestion(questions?.[17].question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions?.[18]?.question)?.questionAnswer) {
      setProgrammeFormValue(
        'areaSubClassification',
        checkedquestion(questions?.[18].question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions?.[19]?.question)?.questionAnswer) {
      setProgrammeFormValue(
        'area',
        checkedquestion(questions?.[19].question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions?.[20]?.question)?.questionAnswer) {
      setProgrammeFormValue(
        'municipality',
        checkedquestion(questions?.[20].question)?.questionAnswer!
      );
    }
  }, []);

  useEffect(() => {
    if (ownTheProperty === false) {
      setProgrammeFormValue('haveTheTitleDeeds', false);
    }
  }, [ownTheProperty, setProgrammeFormValue]);

  useEffect(() => {
    if (ownTheProperty === true) {
      setProgrammeFormValue('liveAtTheProperty', undefined);
    }
  }, [ownTheProperty, setProgrammeFormValue]);

  const onDownloadImageR4c = () => {
    const pdfUrl = tool_R4c_form;
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.setAttribute('download', 'r4b_form.pdf');
    document.body.appendChild(link);
    link.click();
  };

  const onDownloadImageR4b = () => {
    const pdfUrl = tool_R4b_form;
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.setAttribute('download', 'r4b_form.pdf');
    document.body.appendChild(link);
    link.click();
  };

  const onDownloadImageR4a = () => {
    const pdfUrl = tool_R4a_form;
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.setAttribute('download', 'r4a_form.pdf');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <BannerWrapper
        showBackground={false}
        size="medium"
        renderBorder={true}
        title={'Programme details'}
        subTitle={'Step 1 of 4'}
        color={'primary'}
        onBack={() => setActiveStep(SmartSpaceChecklistStepsSteps.INITIAL)}
        displayOffline={!isOnline}
        className="pb-16"
      >
        <div
          className={`p-4 ${
            Boolean(checkedquestion(questions?.[0].question))
              ? 'pointer-events-none'
              : ''
          }`}
        >
          <Typography
            type={'h2'}
            text={'Programme details'}
            color={'textDark'}
            className={'my-3'}
          />

          {disableSection && (
            <Alert
              className="my-4"
              type="warning"
              title="You are viewing this form and cannot edit responses."
              list={['This form should be filled in by the trainee.']}
            />
          )}
          <Typography
            type={'h4'}
            text={'Child protection and confidentiality'}
            color={'textDark'}
            className={'my-3'}
          />
          <div className="flex items-center">
            <div className="'flex items-center' w-full flex-row justify-start">
              <div
                className="flex items-start gap-2"
                onClick={() => {
                  setProgrammeFormValue(
                    'haveReadTheSmartStarterInformation',
                    !getProgrammeFormValues().haveReadTheSmartStarterInformation
                  );
                  onOptionSelected(
                    !getProgrammeFormValues()
                      .haveReadTheSmartStarterInformation,
                    0
                  );
                }}
              >
                <Checkbox<ProgrammeDetailsModel>
                  checked={
                    Boolean(
                      checkedquestion(questions?.[0].question)?.questionAnswer
                    ) ||
                    getProgrammeFormValues().haveReadTheSmartStarterInformation
                  }
                  register={programmeFormRegister}
                  nameProp={'haveReadTheSmartStarterInformation'}
                  onCheckboxChange={() =>
                    onOptionSelected(
                      !getProgrammeFormValues()
                        .haveReadTheSmartStarterInformation,
                      0
                    )
                  }
                  value={
                    String(
                      getProgrammeFormValues()
                        .haveReadTheSmartStarterInformation
                    ) ||
                    checkedquestion(questions?.[0].question)?.questionAnswer!
                  }
                  disabled={Boolean(checkedquestion(questions?.[0].question))}
                ></Checkbox>
                <Typography
                  text={questions?.[0].question}
                  type="body"
                  color={'textMid'}
                />
              </div>
            </div>

            <Typography
              type={'body'}
              text={'View'}
              color={'secondary'}
              className={'my-3'}
              underline={true}
              onClick={() => {
                displayArticle(
                  ContentConsentTypeEnum.ChildProtection,
                  'Child Protection'
                );
              }}
            />
          </div>
        </div>
        <div className="mt-2 space-y-4 p-4">
          <>
            <FormInput<ProgrammeDetailsModel>
              label={questions?.[1].question}
              error={errors['programmeName']}
              register={programmeFormRegister}
              nameProp={'programmeName'}
              placeholder={'E.g. Little Lambs Preschool'}
              type={'text'}
              onChange={(e) => {
                var value = (e.target as HTMLInputElement).value;
                setProgrammeFormValue('programmeName', value);
                onOptionSelected(value, 1);
              }}
              disabled={Boolean(checkedquestion(questions?.[1].question))}
            ></FormInput>
            <div
              className={`w-full ${
                Boolean(checkedquestion(questions?.[2].question))
                  ? 'pointer-events-none'
                  : ''
              }`}
            >
              <label className={styles.label}>{questions?.[2].question}</label>
              <div className="mt-1">
                <Controller
                  name={'programmeType'}
                  control={programmeFormControl}
                  render={({ field: { onChange, value, ref } }) => (
                    <ButtonGroup<string | string[]>
                      inputRef={ref}
                      options={
                        (programData &&
                          programData.map((x: ProgrammeTypeDto) => {
                            return { text: x.description, value: x.id ?? '' };
                          })) ||
                        []
                      }
                      multiple={false}
                      onOptionSelected={(e) => {
                        setProgrammeFormValue('programmeType', e as string);
                        onOptionSelected(e, 2);
                      }}
                      selectedOptions={value}
                      color="secondary"
                      type={ButtonGroupTypes.Button}
                      className={'w-full'}
                    />
                  )}
                ></Controller>
              </div>
            </div>
            <div
              className={`${
                Boolean(checkedquestion(questions?.[9].question))
                  ? 'pointer-events-none'
                  : ''
              }`}
            >
              <Dropdown
                placeholder={'Area Classification'}
                list={
                  (areaClassifications &&
                    areaClassifications.map((area: string) => ({
                      label: area,
                      value: area || '',
                    }))) ||
                  []
                }
                fillType="clear"
                fullWidth={true}
                label={'Area Classification'}
                className={classNames(styles.divider, 'w-full')}
                selectedValue={getProgrammeFormValues().areaClassification}
                onChange={(value: string) => {
                  setProgrammeFormValue('areaClassification', value);
                  onOptionSelected(value, 1);
                }}
              />

              <Dropdown
                placeholder={'Area Sub Classification'}
                list={
                  (areasSubClassifications &&
                    areasSubClassifications.map((area: string) => ({
                      label: area,
                      value: area || '',
                    }))) ||
                  []
                }
                fillType="clear"
                fullWidth={true}
                label={'Area Sub Classification'}
                className={classNames(styles.divider, 'w-full')}
                selectedValue={getProgrammeFormValues().areaSubClassification}
                onChange={(value: string) => {
                  setProgrammeFormValue('areaSubClassification', value);
                }}
              />

              <div className="mt-4">
                <FormInput<ProgrammeDetailsModel>
                  label={questions?.[4].question}
                  error={errors['addressLine1']}
                  register={programmeFormRegister}
                  nameProp={'addressLine1'}
                  placeholder={'E.g. 11 Green Road'}
                  type={'text'}
                  onChange={(e) => {
                    var value = (e.target as HTMLInputElement).value;
                    setProgrammeFormValue('addressLine1', value);
                    onOptionSelected(value, 4);
                  }}
                  disabled={Boolean(checkedquestion(questions?.[4].question))}
                ></FormInput>
              </div>

              <div className="mt-4">
                <FormInput<ProgrammeDetailsModel>
                  label={questions?.[5].question}
                  error={errors['addressLine2']}
                  register={programmeFormRegister}
                  nameProp={'addressLine2'}
                  placeholder={'E.g. Mamelodi East'}
                  type={'text'}
                  onChange={(e) => {
                    var value = (e.target as HTMLInputElement).value;
                    setProgrammeFormValue('addressLine2', value);
                    onOptionSelected(value, 5);
                  }}
                  disabled={Boolean(checkedquestion(questions?.[5].question))}
                ></FormInput>
              </div>

              <Dropdown
                placeholder={'Choose province'}
                list={
                  (provinces &&
                    provinces.map((province: ProvinceDto) => ({
                      label: province.description,
                      value: province.id || '',
                    }))) ||
                  []
                }
                fillType="clear"
                fullWidth={true}
                label={'Province'}
                className={classNames(styles.divider, 'w-full')}
                selectedValue={getProgrammeFormValues().provinceId}
                onChange={(value: string) => {
                  setProgrammeFormValue('provinceId', value);
                }}
              />

              <div className="mt-4">
                <FormInput<ProgrammeDetailsModel>
                  label={questions?.[20].question}
                  error={errors['municipality']}
                  register={programmeFormRegister}
                  nameProp={'municipality'}
                  placeholder={'e.g. City of Cape Town Metropolitan'}
                  type={'text'}
                  onChange={(e) => {
                    var value = (e.target as HTMLInputElement).value;
                    setProgrammeFormValue('municipality', value);
                    onOptionSelected(value, 20);
                  }}
                ></FormInput>
              </div>

              <div className="mt-4">
                <FormInput<ProgrammeDetailsModel>
                  label={questions?.[19].question}
                  error={errors['area']}
                  register={programmeFormRegister}
                  nameProp={'area'}
                  placeholder={'e.g. Bellville'}
                  type={'text'}
                  onChange={(e) => {
                    var value = (e.target as HTMLInputElement).value;
                    setProgrammeFormValue('area', value);
                    onOptionSelected(value, 19);
                  }}
                ></FormInput>
              </div>

              <div className="mt-4">
                <FormInput<ProgrammeDetailsModel>
                  label={questions?.[6].question}
                  register={programmeFormRegister}
                  error={errors['addressLine3']}
                  nameProp={'addressLine3'}
                  placeholder={'E.g. Cape Town'}
                  type={'text'}
                  onChange={(e) => {
                    var value = (e.target as HTMLInputElement).value;
                    setProgrammeFormValue('addressLine3', value);
                    onOptionSelected(value, 6);
                  }}
                  disabled={Boolean(checkedquestion(questions?.[6].question))}
                ></FormInput>
              </div>

              <div className="mt-4">
                <FormInput<ProgrammeDetailsModel>
                  label={questions?.[8].question}
                  error={errors['postalCode']}
                  register={programmeFormRegister}
                  nameProp={'postalCode'}
                  placeholder={'E.g. 0081'}
                  type={'text'}
                  onChange={(e) => {
                    var value = (e.target as HTMLInputElement).value;
                    setProgrammeFormValue('postalCode', value);
                    onOptionSelected(value, 8);
                  }}
                  disabled={Boolean(checkedquestion(questions?.[8].question))}
                ></FormInput>
              </div>
            </div>

            <div className="mt-4">
              <FormInput<ProgrammeDetailsModel>
                label={questions?.[3].question}
                error={errors['ward']}
                register={programmeFormRegister}
                nameProp={'ward'}
                placeholder={'e.g. 0721'}
                type={'text'}
                onChange={(e) => {
                  var value = (e.target as HTMLInputElement).value;
                  setProgrammeFormValue('ward', value);
                  onOptionSelected(value, 3);
                }}
                disabled={Boolean(checkedquestion(questions?.[3].question))}
              ></FormInput>
            </div>

            <div className={'w-full'}>
              <label className={styles.label}>{questions?.[9].question}</label>
              <div
                className={`mt-1 ${
                  Boolean(checkedquestion(questions?.[9].question))
                    ? 'pointer-events-none'
                    : ''
                }`}
              >
                <ButtonGroup<boolean | undefined>
                  options={yesNoOptions}
                  onOptionSelected={(value: any) => {
                    setProgrammeFormValue('ownTheProperty', value as boolean, {
                      shouldValidate: true,
                    });
                    onOptionSelected(value, 9);
                  }}
                  selectedOptions={[getProgrammeFormValues().ownTheProperty]}
                  color="secondary"
                  type={ButtonGroupTypes.Button}
                  className={'w-full'}
                />
              </div>
            </div>

            {ownTheProperty === true && (
              <div className={'w-full'}>
                <label className={styles.label}>
                  {questions?.[10].question}
                </label>
                <div
                  className={`mt-1 ${
                    Boolean(checkedquestion(questions?.[10].question))
                      ? 'pointer-events-none'
                      : ''
                  }`}
                >
                  <ButtonGroup<boolean | undefined>
                    options={yesNoOptions}
                    onOptionSelected={(value: any) => {
                      setProgrammeFormValue(
                        'haveTheTitleDeeds',
                        value as boolean,
                        {
                          shouldValidate: true,
                        }
                      );
                      onOptionSelected(value, 5);
                    }}
                    selectedOptions={[
                      getProgrammeFormValues().haveTheTitleDeeds,
                    ]}
                    color="secondary"
                    type={ButtonGroupTypes.Button}
                    className={'w-full'}
                  />
                </div>
              </div>
            )}
            {ownTheProperty === false && (
              <div className={'w-full'}>
                <label className={styles.label}>
                  {questions?.[14].question}
                </label>
                <div
                  className={`mt-1 ${
                    Boolean(checkedquestion(questions?.[14].question))
                      ? 'pointer-events-none'
                      : ''
                  }`}
                >
                  <ButtonGroup<boolean | undefined>
                    options={yesNoOptions}
                    onOptionSelected={(value: any) => {
                      setProgrammeFormValue(
                        'liveAtTheProperty',
                        value as boolean,
                        {
                          shouldValidate: true,
                        }
                      );
                      onOptionSelected(value, 9);
                    }}
                    selectedOptions={[
                      getProgrammeFormValues().liveAtTheProperty,
                    ]}
                    color="secondary"
                    type={ButtonGroupTypes.Button}
                    className={'w-full'}
                  />
                </div>
              </div>
            )}
            {liveAtTheProperty === true && !disableSection && (
              <>
                <Alert
                  className="mb-4"
                  type="info"
                  title={`The owner or landlord of the property will need to fill in the “confirmation of lease” with you (form R4b).`}
                  list={[
                    'Fill in the form with your landlord and upload a picture of it below.',
                  ]}
                  button={
                    <Button
                      onClick={onDownloadImageR4b}
                      text="Download the R4b form"
                      icon="DownloadIcon"
                      type={'filled'}
                      color={'primary'}
                      textColor={'white'}
                    />
                  }
                />
                <ImageInput<ProgrammeDetailsModel>
                  acceptedFormats={acceptedFormats}
                  label={questions?.[16].question}
                  nameProp="r4bPhoto"
                  icon="CameraIcon"
                  className={'py-4'}
                  currentImageString={r4bPhotoUrl}
                  register={programmeFormRegister}
                  overrideOnClick={() => setPhotoActionBarVisible(true)}
                  onValueChange={(imageString: string) => {
                    setProgrammeFormValue('r4bPhoto', imageString);
                    triggerR4bForm();
                  }}
                  disabled={Boolean(checkedquestion(questions?.[16].question))}
                ></ImageInput>
              </>
            )}
            {liveAtTheProperty === false && (
              <div className={disableSection ? 'pointer-events-none' : ''}>
                <Alert
                  className="mb-4"
                  type="info"
                  title={`The owner or landlord of the property will need to fill in the “Agreement for premises to be used for early learning programme” with you (form 4a).`}
                  list={[
                    'Fill in the form with your landlord and upload a picture of it below.',
                  ]}
                  button={
                    <Button
                      onClick={onDownloadImageR4a}
                      text="Download the R4a form"
                      icon="DownloadIcon"
                      type={'filled'}
                      color={'primary'}
                      textColor={'white'}
                    />
                  }
                />
                <ImageInput<ProgrammeDetailsModel>
                  acceptedFormats={acceptedFormats}
                  label={questions?.[15].question}
                  nameProp="r4bPhoto"
                  icon="CameraIcon"
                  className={'py-4'}
                  currentImageString={r4bPhotoUrl}
                  register={programmeFormRegister}
                  overrideOnClick={() => setPhotoActionBarVisible(true)}
                  onValueChange={(imageString: string) => {
                    setProgrammeFormValue('r4bPhoto', imageString);
                    triggerR4bForm();
                  }}
                  disabled={Boolean(checkedquestion(questions?.[15].question))}
                ></ImageInput>
              </div>
            )}
          </>
          {haveTheTitleDeeds === false && ownTheProperty === true && (
            <div className={'w-full'}>
              <label className={styles.label}>{questions?.[11].question}</label>
              <div
                className={`mt-1 ${
                  Boolean(checkedquestion(questions?.[11].question))
                    ? 'pointer-events-none'
                    : ''
                }`}
              >
                <Controller
                  name="unproclaimedLand"
                  control={programmeFormControl}
                  render={({ field: { onChange, value, ref } }) => (
                    <ButtonGroup<boolean>
                      inputRef={ref}
                      options={yesNoOptions}
                      onOptionSelected={(value: any) => {
                        setProgrammeFormValue(
                          'unproclaimedLand',
                          value as boolean,
                          {
                            shouldValidate: true,
                          }
                        );
                        onOptionSelected(value, 6);
                      }}
                      selectedOptions={value}
                      color="secondary"
                      type={ButtonGroupTypes.Button}
                      className={'w-full'}
                    />
                  )}
                />
              </div>
            </div>
          )}

          {haveTheTitleDeeds === true && (
            <ImageInput<ProgrammeDetailsModel>
              acceptedFormats={acceptedFormats}
              label={questions?.[12].question}
              nameProp="r4bPhoto"
              icon="CameraIcon"
              className={'py-4'}
              currentImageString={r4bPhotoUrl}
              register={programmeFormRegister}
              overrideOnClick={() => setPhotoActionBarVisible(true)}
              onValueChange={(imageString: string) => {
                setProgrammeFormValue('r4bPhoto', imageString);
                triggerR4bForm();
              }}
              disabled={Boolean(checkedquestion(questions?.[12].question))}
            ></ImageInput>
          )}

          {unproclaimedLand === false && !disableSection && (
            <Alert
              className="mb-4"
              type="warning"
              title={`Apply to your municipality for a copy of the title deeds.`}
              list={[
                'You will need to get a copy of the title deeds in order to complete this section.',
                'Once you have the title deeds, answer “Yes” to the Title Deeds question above and add a photo of the document.',
              ]}
            />
          )}

          {unproclaimedLand === true && (
            <div className={disableSection ? 'pointer-events-none' : ''}>
              <Alert
                className="mb-4"
                type="info"
                title={`Please get a signed, stamped copy of an R4c form instead.`}
                list={[
                  'This form can be stamped by any commissioner of oaths. This could include: police officers, South African Post Office workers, lawyers, or accountants for example.',
                ]}
                button={
                  <Button
                    onClick={onDownloadImageR4c}
                    text="Download the R4c form"
                    icon="DownloadIcon"
                    type={'filled'}
                    color={'primary'}
                    textColor={'white'}
                  />
                }
              />
              <ImageInput<ProgrammeDetailsModel>
                acceptedFormats={acceptedFormats}
                label={questions?.[13].question}
                nameProp="r4bPhoto"
                icon="CameraIcon"
                className={'py-4'}
                currentImageString={r4bPhotoUrl}
                register={programmeFormRegister}
                overrideOnClick={() => setPhotoActionBarVisible(true)}
                onValueChange={(imageString: string) => {
                  setProgrammeFormValue('r4bPhoto', imageString);
                  triggerR4bForm();
                }}
              ></ImageInput>
            </div>
          )}
          <div>
            <div>
              <Button
                type="filled"
                color="primary"
                className={styles.button}
                disabled={
                  !isValid ||
                  Boolean(
                    !getProgrammeFormValues().haveReadTheSmartStarterInformation
                  )
                }
                onClick={() => {
                  changeSmartSpaceCheckAddress();
                  setVisitSection(visitSection);
                  onSubmitAndContinue();
                }}
              >
                {renderIcon('ArrowCircleRightIcon', 'mr-2 text-white w-5')}
                <Typography
                  type={'help'}
                  text={'Save & continue'}
                  color={'white'}
                />
              </Button>
            </div>
          </div>
        </div>
      </BannerWrapper>
      {contentConsentTypeEnum && (
        <Article
          consentEnumType={contentConsentTypeEnum}
          visible={presentArticle}
          title={articleTitle}
          onClose={() => setPresentArticle(false)}
          isOpen={true}
        />
      )}
      {/* <Dialog visible={showMap} position={DialogPosition.Bottom} stretch>
        <AddressMap
          onClose={() => setShowMap?.(false)}
          onSubmit={(address) => {
            setProgrammeFormValue('programmeAddress', address);
            setEditedAddress(address);
            onOptionSelected(address, 3);
          }}
        />
      </Dialog> */}
      <Dialog
        visible={photoActionBarVisible}
        position={DialogPosition.Bottom}
        stretch
      >
        <div>
          <PhotoPrompt
            title={'Upload image'}
            onClose={() => setPhotoActionBarVisible(false)}
            onAction={(imageUrl: string) => {
              setPhotoUrl(imageUrl);
              onOptionSelected(imageUrl, 7);
            }}
            onDelete={() => deleteBirthDocumentPhoto()}
          ></PhotoPrompt>
        </div>
      </Dialog>
    </>
  );
};
