export const bannerContent = 'px-4 z-10';
export const welcomeText = 'w-full py-30 break-words';
export const wrapper =
  'w-full flex flex-col justify-between items-start mb-4 md:mb-0';
export const classRoomIcon = 'bg-secondary text-white';
export const businessIcon = 'bg-uiLight text-primary';
export const smartStarterIcon = 'bg-secondary text-white';
export const calendarIcon = 'bg-uiLight text-primary';
export const trainingIcon = 'bg-uiLight text-primary';
export const communityIcon = 'bg-uiLight text-primary';
export const icon = 'bg-tertiary text-white';
