import * as Yup from 'yup';

export interface ConsolidationMeetingModel {
  meetingAttended: boolean;
}

export const initialConsolidationSupportValues: ConsolidationMeetingModel = {
  meetingAttended: false,
};

export const ConsolidationSupportSchema = Yup.object().shape({
  haveSupport: Yup.boolean().required(),
});
