import { CalendarEventModel } from '@ecdlink/core';
import * as Yup from 'yup';
import { ListDataItem } from '../calendar.types';

export type CalendarAddEventInfo = {
  id?: string;
  eventTypeDisabled?: boolean;
  eventType?:
    | 'SmartSpace'
    | 'First PQA'
    | 'PQA follow-up'
    | 'First site visit'
    | 'Second site visit'
    | 'Re-accreditation'
    | 'General support visit'
    | 'Coaching circle'
    | 'Re-accreditation follow-up'
    | 'Club Monthly Meeting';
  start?: string;
  end?: string;
  minDate?: string;
  maxDate?: string;
  allDay?: boolean;
  name?: string;
  description?: string;
  participantUserIds?: string[];
  action?: {
    buttonName: string;
    buttonIcon?: string;
    url: string;
    state?: any;
  };
};

export type CalendarEditEventInfo = {
  id: string;
  minDate?: string;
  maxDate?: string;
};

export interface CalendarAddEventProps {
  event?: CalendarAddEventInfo;
  guests?: ListDataItem[];
  optionsToHide?: CalendarAddEventInfo['eventType'][];
  eventTypeDisabled?: boolean;
  hideAddParticipantsButton?: boolean;
  onUpdated: (isNew: boolean, event: CalendarEventModel) => void;
  onCancel: () => void;
}

export interface CalendarAddEventParticipantFormModel {
  userId: string;
  firstName: string;
  surname: string;
  isClub: boolean;
}

export interface CalendarAddEventFormModel {
  name: string;
  start: Date;
  end: Date;
  allDay: boolean;
  description: string;
  eventType?: string;
  participants: CalendarAddEventParticipantFormModel[];
}

export const defaultCalendarAddEventFormSchema: CalendarAddEventFormModel = {
  name: '',
  start: new Date(),
  end: new Date(),
  allDay: false,
  description: '',
  eventType: undefined,
  participants: [],
};

export const CalendarAddEventFormSchema = Yup.object().shape({
  name: Yup.string().required(),
  //body: Yup.string().required(),
  start: Yup.date().required(),
  end: Yup.date().required(),
});

export interface CalendarAddEventOptions {
  event: CalendarAddEventInfo;
  guests?: ListDataItem[];
  optionsToHide?: CalendarAddEventInfo['eventType'][];
  onUpdated?: (isNew: boolean, event: CalendarEventModel) => void;
  onCancel?: () => void;
}

export interface CalendarEditEventOptions {
  event: CalendarEditEventInfo;
  eventTypeDisabled?: boolean;
  hideAddParticipantsButton?: boolean;
  onUpdated?: (event: CalendarEventModel) => void;
  onCancel?: () => void;
}
