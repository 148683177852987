import { useOnlineStatus } from '@/hooks/useOnlineStatus';
import {
  SiteAddressDetailsModel,
  SiteAddressDetailsSchema,
} from '@/schemas/trainee/site-address';
import { staticDataSelectors } from '@/store/static-data';
import {
  Button,
  Typography,
  Address,
  FormInput,
  Dropdown,
  classNames,
  BannerWrapper,
} from '@ecdlink/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
// import * as styles from './programme-details.styles';
import * as styles from './site-address.styles';
import { useForm, useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  ClassroomDto,
  PractitionerDto,
  ProvinceDto,
  SiteAddressDto,
} from '@/../../../packages/core/lib';
import { traineeSelectors } from '@/store/trainee';
import {
  classroomsActions,
  classroomsSelectors,
  classroomsThunkActions,
} from '@/store/classroom';
import { newGuid } from '@/utils/common/uuid.utils';
import { cloneDeep } from '@apollo/client/utilities';
import { useAppDispatch } from '@/store';

interface Step6MapProps {
  traineeId: string;
  onClose: () => void;
  onSubmit: (siteAddress: string) => void;
}

export const Step6Map: React.FC<Step6MapProps> = ({
  onClose,
  onSubmit,
  traineeId,
}) => {
  const {
    getValues: getSiteAddressFormValues,
    setValue: setSiteAddressFormValue,
    register: siteAddressFormRegister,
    control: siteAddressFormControl,
  } = useForm<SiteAddressDetailsModel>({
    resolver: yupResolver(SiteAddressDetailsSchema),
    //shouldUnregister: true,
    mode: 'onChange',
  });
  const appDispatch = useAppDispatch();
  const { isOnline } = useOnlineStatus();

  const provinces = useSelector(staticDataSelectors.getProvinces);
  const areaClassifications = ['Urban', 'Rural', 'Informal Settlement'];
  const areasSubClassifications = [
    'Metro',
    'City',
    'Town / Township',
    'Village',
    'Informal Settlement',
  ];

  const { isValid, errors } = useFormState({ control: siteAddressFormControl });
  const classroomGroups = useSelector(
    classroomsSelectors.getClassroomGroupsForUser(traineeId)
  );
  const classroom = classroomGroups[0].classroom;

  const changeSmartSpaceCheckAddress = async () => {
    const classroomCopy = cloneDeep(classroom);
    if (classroomCopy) {
      const siteAddressId =
        classroomCopy.siteAddress != null
          ? classroomCopy.siteAddress.id
          : newGuid();
      const siteAddress: SiteAddressDto = {
        name:
          classroomCopy.siteAddress?.name != null
            ? classroomCopy.siteAddress?.name
            : '',
        areaClassification: getSiteAddressFormValues().areaClassification || '',
        areaSubClassification:
          getSiteAddressFormValues().areaSubClassification || '',
        addressLine1: getSiteAddressFormValues().addressLine1 || '',
        addressLine2: getSiteAddressFormValues().addressLine2 || '',
        provinceId: getSiteAddressFormValues().provinceId || '',
        municipality: getSiteAddressFormValues().municipality || '',
        area: getSiteAddressFormValues().area || '',
        addressLine3: getSiteAddressFormValues().addressLine3 || '', // City
        postalCode: getSiteAddressFormValues().postalCode || '',
        ward: getSiteAddressFormValues().ward || '',
      };
      classroomCopy.siteAddress = siteAddress;
      classroomCopy.siteAddressId = siteAddressId;
      if (classroomCopy.siteAddress) {
        appDispatch(
          classroomsActions.updateClassroomSiteAddress(
            classroomCopy as ClassroomDto
          )
        );

        if (isOnline) {
          await appDispatch(
            classroomsThunkActions.upsertClassroomSiteAddress({})
          );
        }
      }
    }
  };

  const [province, setProvince] = useState('');
  const [areaClassification, setAreaClassification] = useState('');
  const [areaSubClassification, setAreaSubClassification] = useState('');
  const [questions, setAnswers] = useState([
    {
      question: 'Address line 1',
      answer: '',
    },
    {
      question: 'Address line 2',
      answer: '',
    },
    {
      question: 'City',
      answer: '',
    },
    {
      question: 'Postal Code',
      answer: '',
    },
    {
      question: 'Area Classification',
      answer: '',
    },
    {
      question: 'Area Sub Classification',
      answer: '',
    },
    {
      question: 'Province',
      answer: '',
    },
    {
      question: 'Area',
      answer: '',
    },
    {
      question: 'Municipality',
      answer: '',
    },
    {
      question: 'Ward number',
      answer: '',
    },
  ]);

  useEffect(() => {
    if (classroom != null && classroom.siteAddress != null) {
      if (
        classroom.siteAddress.addressLine1 != null &&
        questions[0].answer == ''
      ) {
        setSiteAddressFormValue(
          'addressLine1',
          classroom.siteAddress.addressLine1
        );
        updateFieldChanged(0, classroom.siteAddress.addressLine1);
      }

      if (
        classroom.siteAddress.addressLine2 != null &&
        questions[1].answer == ''
      ) {
        setSiteAddressFormValue(
          'addressLine2',
          classroom.siteAddress.addressLine2
        );
        updateFieldChanged(1, classroom.siteAddress.addressLine2);
      }

      if (
        classroom.siteAddress.addressLine3 != null &&
        questions[2].answer == ''
      ) {
        setSiteAddressFormValue(
          'addressLine3',
          classroom.siteAddress.addressLine3
        );
        updateFieldChanged(2, classroom.siteAddress.addressLine3);
      }

      if (classroom.siteAddress.ward != null && questions[9].answer == '') {
        setSiteAddressFormValue('ward', classroom.siteAddress.ward);
        updateFieldChanged(9, classroom.siteAddress.ward);
      }

      if (
        classroom.siteAddress.postalCode != null &&
        questions[3].answer == ''
      ) {
        setSiteAddressFormValue('postalCode', classroom.siteAddress.postalCode);
        updateFieldChanged(3, classroom.siteAddress.postalCode);
      }

      if (classroom.siteAddress.area != null && questions[7].answer == '') {
        setSiteAddressFormValue('area', classroom.siteAddress.area);
        updateFieldChanged(7, classroom.siteAddress.area);
      }

      if (
        classroom.siteAddress.areaClassification != null &&
        questions[4].answer == ''
      ) {
        setSiteAddressFormValue(
          'areaClassification',
          classroom.siteAddress.areaClassification
        );
        updateFieldChanged(4, classroom.siteAddress.areaClassification);
        setAreaClassification(classroom.siteAddress.areaClassification);
      }

      if (
        classroom.siteAddress.areaSubClassification != null &&
        questions[5].answer == ''
      ) {
        setSiteAddressFormValue(
          'areaSubClassification',
          classroom.siteAddress.areaSubClassification
        );
        updateFieldChanged(5, classroom.siteAddress.areaSubClassification);
        setAreaSubClassification(classroom.siteAddress.areaSubClassification);
      }

      if (
        classroom.siteAddress.provinceId != null &&
        questions[6].answer == ''
      ) {
        setSiteAddressFormValue('provinceId', classroom.siteAddress.provinceId);
        updateFieldChanged(6, classroom.siteAddress.provinceId);
      }

      if (
        classroom.siteAddress.municipality != null &&
        questions[8].answer == ''
      ) {
        setSiteAddressFormValue(
          'municipality',
          classroom.siteAddress.municipality
        );
        updateFieldChanged(8, classroom.siteAddress.municipality);
      }
    }
  }, []);

  const updateFieldChanged = (index: number, target: any) => {
    let newArr = questions;
    newArr[index].answer = target;

    setAnswers(newArr);
  };

  return (
    <BannerWrapper
      showBackground={false}
      size="medium"
      renderBorder={true}
      title={'Site address details'}
      subTitle={'Update Trainee Address'}
      color={'primary'}
      displayOffline={!isOnline}
      className="pb-16"
    >
      <form className="px-4">
        <div className="mt-4">
          <FormInput<SiteAddressDetailsModel>
            label={questions?.[0].question}
            register={siteAddressFormRegister}
            error={errors['addressLine1']}
            nameProp={'addressLine1'}
            placeholder={'E.g. 11 Green Road'}
            type={'text'}
          ></FormInput>
        </div>

        <div className="mt-4">
          <FormInput<SiteAddressDetailsModel>
            label={questions?.[1].question}
            register={siteAddressFormRegister}
            nameProp={'addressLine2'}
            error={errors['addressLine2']}
            placeholder={'E.g. Mamelodi East'}
            type={'text'}
          ></FormInput>
        </div>

        <div className="mt-4">
          <FormInput<SiteAddressDetailsModel>
            label={questions?.[2].question}
            register={siteAddressFormRegister}
            nameProp={'addressLine3'}
            error={errors['addressLine3']}
            placeholder={'E.g. Cape Town'}
            type={'text'}
          ></FormInput>
        </div>

        <div className="mt-4">
          <FormInput<SiteAddressDetailsModel>
            label={questions?.[3].question}
            register={siteAddressFormRegister}
            nameProp={'postalCode'}
            error={errors['postalCode']}
            placeholder={'E.g. 0081'}
            type={'text'}
          ></FormInput>
        </div>

        <div className="my-4">
          <FormInput<SiteAddressDetailsModel>
            label={questions?.[9].question}
            register={siteAddressFormRegister}
            nameProp={'ward'}
            error={errors['ward']}
            placeholder={'e.g. 0721'}
            type={'text'}
          ></FormInput>
        </div>

        <Dropdown
          placeholder={'Area Classification'}
          list={
            (areaClassifications &&
              areaClassifications.map((area: string) => ({
                label: area,
                value: area || '',
              }))) ||
            []
          }
          fillType="clear"
          fullWidth={true}
          label={questions?.[4].question}
          className={classNames(styles.divider, 'w-full')}
          selectedValue={areaClassification}
          onChange={(value: string) => {
            setSiteAddressFormValue('areaClassification', value, {
              shouldValidate: true,
            });
            setAreaClassification(value);
          }}
        />

        <Dropdown
          placeholder={'Area Sub Classification'}
          list={
            (areasSubClassifications &&
              areasSubClassifications.map((area: string) => ({
                label: area,
                value: area || '',
              }))) ||
            []
          }
          fillType="clear"
          fullWidth={true}
          label={questions?.[5].question}
          className={classNames(styles.divider, 'w-full')}
          selectedValue={areaSubClassification}
          onChange={(value: string) => {
            setSiteAddressFormValue('areaSubClassification', value, {
              shouldValidate: true,
            });
            setAreaSubClassification(value);
          }}
        />

        <Dropdown
          placeholder={'Choose province'}
          list={
            (provinces &&
              provinces.map((province: ProvinceDto) => ({
                label: province.description,
                value: province.id || '',
              }))) ||
            []
          }
          fillType="clear"
          fullWidth={true}
          label={questions?.[6].question}
          className={classNames(styles.divider, 'w-full')}
          selectedValue={province}
          onChange={(value: string) => {
            setSiteAddressFormValue('provinceId', value, {
              shouldValidate: true,
            });
            setProvince(value);
          }}
        />

        <div className="mt-4">
          <FormInput<SiteAddressDetailsModel>
            label={questions?.[7].question}
            register={siteAddressFormRegister}
            nameProp={'area'}
            error={errors['area']}
            placeholder={'e.g. Bellville'}
            type={'text'}
          ></FormInput>
        </div>

        <div className="my-4">
          <FormInput<SiteAddressDetailsModel>
            label={questions?.[8].question}
            register={siteAddressFormRegister}
            nameProp={'municipality'}
            error={errors['municipality']}
            placeholder={'e.g. City of Cape Town Metropolitan'}
            type={'text'}
          ></FormInput>
        </div>
      </form>
      <div className="mb-5 flex flex-col gap-3 px-4">
        <Button
          type="filled"
          color="primary"
          className={'max-h-10 w-full'}
          icon={'SaveIcon'}
          onClick={() => {
            changeSmartSpaceCheckAddress();
            onClose();
          }}
          disabled={!isValid}
        >
          <Typography
            type="help"
            className="mr-2"
            color="white"
            text={'Save'}
          />
        </Button>
        <Button
          type="outlined"
          color="primary"
          className={'max-h-10 w-full'}
          icon={'XCircleIcon'}
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>
    </BannerWrapper>
  );
};
