import {
  ChildProgressObservationReport,
  ChildProgressReportSummaryModel,
} from '@ecdlink/core';
import { createSelector } from '@reduxjs/toolkit';
import { ContentReportState } from '.';
import { isMatchingReportingPeriods } from '@utils/child/child-profile-utils';
import { RootState } from '../../types';
import { UnSyncedReportItem } from './report.types';

export const getChildProgressObservationReports = (childId: string) =>
  createSelector(
    (state: RootState) => state.contentReportData.childProgressionReports || [],
    (
      stateReports: ChildProgressObservationReport[]
    ): ChildProgressObservationReport[] => {
      const reports = stateReports.filter((mr) => {
        return mr.childId === childId;
      });

      return reports;
    }
  );

export const hasUnsyncedReports = createSelector(
  (state: RootState) =>
    state.contentReportData.unsyncedChildProgressReportsIds || [],
  (reportIds: UnSyncedReportItem[]) =>
    reportIds.length > 0 && reportIds.some((r) => r.promptUser === true)
);

export const getChildCompletedObservationReports = (childId?: string) =>
  createSelector(
    (state: RootState) => state.contentReportData.childProgressionReports || [],
    (reports: ChildProgressObservationReport[]) =>
      reports.filter(
        (x) =>
          x.childId === childId &&
          x.dateCompleted !== undefined &&
          x.dateCompleted !== null &&
          x.dateCompleted !== ''
      )
  );

export const getAllChildProgressObservationReports = (
  state: RootState
): ChildProgressObservationReport[] =>
  state.contentReportData.childProgressionReports || [];

export const getChildProgressReportSummaries = (childId?: string) =>
  createSelector(
    (state: RootState) =>
      state.contentReportData.childProgressReportSummaries || [],
    (summaries: ChildProgressReportSummaryModel[]) =>
      summaries
        .filter((summary) => summary.childId === childId)
        .sort((a, b) =>
          new Date(a.reportDate) > new Date(b.reportDate) ? 1 : -1
        )
  );

export const getChildProgressObservationReportByReportId = (reportId: string) =>
  createSelector(
    (state: RootState) => state.contentReportData.childProgressionReports || [],
    (reports: ChildProgressObservationReport[]) =>
      reports.find((report) => report.id === reportId)
  );

export const getChildProgressObservationReportByReportingPeriod = (
  reportingDate: Date,
  childId?: string
) =>
  createSelector(
    (state: RootState) => state.contentReportData.childProgressionReports || [],
    (reports: ChildProgressObservationReport[]) =>
      reports.find(
        (report) =>
          report.childId === childId &&
          isMatchingReportingPeriods(
            new Date(report.reportingDate),
            reportingDate
          )
      )
  );

export const hasChildProgressObservationReportsForReportingPeriod = (
  reportingDate: Date
) =>
  createSelector(
    (state: RootState) => state.contentReportData.childProgressionReports || [],
    (reports: ChildProgressObservationReport[]) =>
      reports.some((report) =>
        isMatchingReportingPeriods(
          new Date(report.reportingDate),
          reportingDate
        )
      )
  );

export const hasChildSummaryReportsForReportingPeriod = (
  reportingDate: Date | undefined
) =>
  createSelector(
    (state: RootState) =>
      state.contentReportData.childProgressReportSummaries || [],
    (reports: ChildProgressReportSummaryModel[]) =>
      reportingDate === undefined
        ? undefined
        : reports.some((report) =>
            isMatchingReportingPeriods(
              new Date(report.reportDate),
              reportingDate
            )
          )
  );

export const getChildLatestCompletedReports = (childId?: string) =>
  createSelector(
    (state: RootState) => state.contentReportData,
    (contentReportState: ContentReportState) => {
      if (!contentReportState) return [];

      const childLocallyCompletedReports =
        contentReportState.childProgressionReports?.filter(
          (report) =>
            (!childId ? true : report.childId === childId) &&
            report.dateCompleted !== undefined &&
            report.dateCompleted !== null &&
            report.dateCompleted !== ''
        ) || [];

      const excludingSummaries =
        contentReportState.childProgressReportSummaries?.filter(
          (summary) =>
            (!childId ? true : summary.childId === childId) &&
            !childLocallyCompletedReports.some(
              (report) => report.id === summary.reportId
            )
        ) || [];

      const completdLocalReportAsSummaries: ChildProgressReportSummaryModel[] =
        childLocallyCompletedReports.map((report) => ({
          childId: report.childId,
          categories: report.categories.map((cat) => ({
            categoryId: cat.categoryId,
            achievedLevelId: cat.achievedLevelId,
            tasks:
              cat.tasks.map((t) => ({
                levelId: t.levelId,
                skillId: t.skillId,
                value: t.value,
              })) || [],
          })),
          childFirstName: report.childFirstname,
          childSurname: report.childSurname,
          reportDate: report.reportingDate,
          reportDateCompleted: report.dateCompleted || '',
          reportDateCreated: report.dateCreated || '',
          reportPeriod: report.reportingPeriod,
          reportId: report.id,
          classroomName: report.classroomName,
        }));

      const mergedSummaries = [
        ...completdLocalReportAsSummaries,
        ...excludingSummaries,
      ];

      const sortedSummaries = mergedSummaries.sort((a, b) =>
        new Date(a.reportDate) > new Date(b.reportDate) ? -1 : 1
      );
      return sortedSummaries;
    }
  );
