import {
  // ClassProgrammeDto,
  ClassroomDto,
  ClassroomGroupDto,
} from '@ecdlink/core';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClassroomService } from '@services/ClassroomService';
import { RootState, ThunkApiType } from '../types';
import { CoachService } from '@/services/CoachService';

export const ClassroomForCoachActions = {
  GET_CLASSROOM_FOR_COACH: 'getClassroomsForCoach',
  GET_CLASSROOM_GROUPS_FOR_COACH: 'getClassroomGroupsForCoach',
};

export const getClassroomForCoach = createAsyncThunk<
  ClassroomDto[],
  // eslint-disable-next-line @typescript-eslint/ban-types
  { id: string },
  ThunkApiType<RootState>
>(
  ClassroomForCoachActions.GET_CLASSROOM_FOR_COACH,
  // eslint-disable-next-line no-empty-pattern
  async ({ id }, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      classroomForCoachData: { classroomForCoach: classroomsForCoachCache },
    } = getState();

    let classroomsForCoach: ClassroomDto[] | undefined;

    if (userAuth?.auth_token) {
      classroomsForCoach = await new ClassroomService(
        userAuth?.auth_token
      ).getAllClassroomForCoach(id);
    } else {
      return rejectWithValue('no access token, profile check required');
    }
    if (!classroomsForCoach) {
      return rejectWithValue('Error getting Classrooms');
    }
    return classroomsForCoach;
  }
);

export const getClassroomGroupsForCoach = createAsyncThunk<
  ClassroomGroupDto[],
  {},
  ThunkApiType<RootState>
>(
  ClassroomForCoachActions.GET_CLASSROOM_GROUPS_FOR_COACH,
  // eslint-disable-next-line no-empty-pattern
  async ({}, { getState, rejectWithValue }) => {
    const {
      auth: { userAuth },
      classroomForCoachData: { classroomGroups: cache },
    } = getState();

    try {
      let groups: ClassroomGroupDto[] | undefined;

      if (userAuth?.auth_token) {
        groups = await new CoachService(
          userAuth?.auth_token
        ).getClassroomGroupsForCoach(userAuth?.id);
      } else {
        return rejectWithValue('no access token, profile check required');
      }

      if (!groups) {
        return rejectWithValue('Error getting Classroom Groups');
      }

      groups.sort((a, b) => {
        return (a.name || '') > (b.name || '') ? 1 : -1;
      });

      return groups;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
