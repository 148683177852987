export const pointsConstants = {
  practitionerMonthlyMax: 100,
  principalOrAdminMonthlyMax: 150,
  practitionerYearlyMax: 1200,
  principalOrAdminYearlyMax: 1925,
};

export const pointsActivitiesIds = {
  ChildRegistration: '13a6e446-d011-407a-aebb-2a398915d6ae',
  ChildRemoval: 'd38885e1-a822-4dd9-af3a-252681b27dbb',
  SubmitIncomeStatement: '8021a70d-3267-48aa-8acc-33a22736004d',
  SubmitIncomeStatementBonus: '4d49baed-8fff-49ad-883f-d60d62a58d16',
  SubmitAttendance: 'aad9c9aa-f76f-466b-bffe-fd9119efac31',
  MonthlyPreschoolFeesAdded: '1aea269b-db0b-4cc6-b052-c4eaa5d89b05',
  MonthlyPreschoolFeeUpdated: 'f7307227-2ff7-4b85-8851-27c2af79be28',
};
