import { practitionerSelectors } from '@/store/practitioner';
import {
  Alert,
  BannerWrapper,
  Button,
  Checkbox,
  DatePicker,
  Typography,
} from '@ecdlink/ui';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useOnlineStatus } from '@/hooks/useOnlineStatus';
import { format } from 'date-fns';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ConsolidationMeetingModel,
  ConsolidationSupportSchema,
  initialConsolidationSupportValues,
} from '@/schemas/trainee/consolidation-meeting';
import { TraineeService } from '@/services/TraineeService';
import { authSelectors } from '@/store/auth';
import { timelineSteps } from '../trainee-onboarding-dashboard/timeline-steps';
import {
  traineeActions,
  traineeSelectors,
  traineeThunkActions,
} from '@/store/trainee';
import { useAppDispatch } from '@store';
import {
  notificationActions,
  notificationsSelectors,
} from '@/store/notifications';
import { disableBackendNotification } from '@/store/notifications/notifications.actions';
import { notificationTagConfig } from '@/constants/notifications';
import { coachSelectors } from '@/store/coach';
interface ConsolidationMeetingScheduledProps {
  setNotificationStep: any;
  practitionerUserId?: any;
}

export const ConsolidationMeetingScheduled: React.FC<
  ConsolidationMeetingScheduledProps
> = ({ setNotificationStep, practitionerUserId }) => {
  const { isOnline } = useOnlineStatus();
  const appDispatch = useAppDispatch();
  const practitioner = useSelector(practitionerSelectors.getPractitioner);
  const date = format(new Date(), 'EEEE, d LLLL');
  const [selectedDate, setSelectedDate] = useState<Date>();
  const userAuth = useSelector(authSelectors.getAuthUser);
  const coach = useSelector(coachSelectors.getCoach);
  const isCoach = coach?.user?.id === userAuth?.id;

  const notification = useSelector(
    notificationsSelectors.getAllNotifications
  ).find((item) =>
    item?.message?.cta?.includes(notificationTagConfig?.LearnMore?.cta ?? '')
  );

  const {
    control,
    setValue,
    register: consolidationMeetingRegister,
    getValues: consolidationMeetingGetValues,
  } = useForm<ConsolidationMeetingModel>({
    resolver: yupResolver(ConsolidationSupportSchema),
    mode: 'onChange',
    defaultValues: initialConsolidationSupportValues,
  });

  const { meetingAttended } = useWatch({ control });

  const sendConsolidationMeetingAttended = async () => {
    if (!practitionerUserId && selectedDate) {
      await new TraineeService(
        userAuth?.auth_token!
      ).updateConsolidationMeeting(
        practitioner?.userId!,
        consolidationMeetingGetValues().meetingAttended,
        selectedDate
      );
    } else {
      if (selectedDate) {
        await new TraineeService(
          userAuth?.auth_token!
        ).updateConsolidationMeeting(
          practitionerUserId,
          consolidationMeetingGetValues().meetingAttended,
          selectedDate
        );
      }

      await appDispatch(
        traineeThunkActions.getTraineeTimeline({
          userId: practitionerUserId,
        })
      ).unwrap();
    }
    setNotificationStep('');
  };

  const practitionerId = practitionerUserId || practitioner?.userId || '';
  const timeline = useSelector(
    traineeSelectors.getTraineeOnboardTimeline(practitionerId)
  );

  const completedSteps = timelineSteps(
    timeline!,
    () => {},
    false,
    isOnline,
    // @ts-ignore
    undefined
  ).filter((item) => item?.type === 'completed');

  useEffect(() => {
    if (
      completedSteps &&
      completedSteps.some((x) => x.title === 'Consolidation meeting attended')
    ) {
      setValue('meetingAttended', !meetingAttended);
      if (timeline?.consolidationDeadlineDate) {
        setSelectedDate(new Date(timeline?.consolidationMeetingDate));
      }
    }
  }, []);

  return (
    <>
      <BannerWrapper
        showBackground={false}
        size="medium"
        renderBorder={true}
        title={'Business'}
        subTitle={date}
        color={'primary'}
        onBack={() => setNotificationStep('')}
        displayOffline={!isOnline}
        className="pb-16"
      >
        <div className="flex flex-col justify-around p-4">
          <div>
            <Typography
              className={'my-3'}
              color={'textDark'}
              type={'h2'}
              text={'Consolidation meeting'}
            />
            <div className="'flex items-center' w-full flex-row justify-start">
              <div
                className="flex items-start gap-2"
                onClick={() => setValue('meetingAttended', !meetingAttended)}
              >
                <Checkbox<ConsolidationMeetingModel>
                  onCheckboxChange={() =>
                    setValue('meetingAttended', !meetingAttended)
                  }
                  register={consolidationMeetingRegister}
                  nameProp={'meetingAttended'}
                  checked={
                    consolidationMeetingGetValues().meetingAttended ||
                    completedSteps.some(
                      (x) => x.title === 'Consolidation meeting attended'
                    )
                  }
                  name="meetingAttended"
                  disabled={completedSteps.some(
                    (x) => x.title === 'Consolidation meeting attended'
                  )}
                ></Checkbox>
                <Typography
                  text={
                    isCoach
                      ? 'Consolidation Meeting Attended / Consolidation Call?'
                      : 'Consolidation Meeting Attended'
                  }
                  type="body"
                  color={'textMid'}
                />
              </div>
              <div className="my-5 w-60">
                <Typography
                  text={'Date of attendance:'}
                  type="body"
                  color={'textMid'}
                />
                <DatePicker
                  placeholderText={'Please select a date'}
                  value={selectedDate?.toDateString()}
                  className="focus:border-primary focus:ring-primary w-100 z-50 mt-1 rounded-md text-sm text-black shadow-sm"
                  selected={selectedDate ? new Date(selectedDate) : undefined}
                  onChange={(date: Date) => setSelectedDate(date)}
                  dateFormat="EEE, dd MMM yyyy"
                  showYearDropdown
                  scrollableYearDropdown
                />
              </div>
            </div>
            <div className="mt-4 mb-16 h-full w-full">
              <Button
                size="normal"
                className="mb-4 w-full"
                type="filled"
                color="primary"
                text="Save"
                textColor="white"
                icon="ArrowCircleRightIcon"
                disabled={
                  !isCoach &&
                  (!consolidationMeetingGetValues().meetingAttended ||
                    !selectedDate ||
                    completedSteps.some(
                      (x) => x.title === 'Consolidation meeting attended'
                    ))
                }
                onClick={sendConsolidationMeetingAttended}
              />
            </div>
          </div>
        </div>
      </BannerWrapper>
    </>
  );
};
