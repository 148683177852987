import { useHistory, useLocation } from 'react-router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDialog, useTheme } from '@ecdlink/core';
import {
  BannerWrapper,
  Button,
  Dialog,
  DialogPosition,
  Divider,
  ProfileAvatar,
  renderIcon,
  StatusChip,
  Typography,
  Card,
  ActionModal,
  MenuListDataItem,
  StackedList,
} from '@ecdlink/ui';
import {
  ClassroomMetricReport,
  NoteTypeEnum,
  PractitionerRemovalHistory,
} from '@ecdlink/graphql';
import { getLogo, LogoSvgs } from '@utils/common/svg.utils';
import { formatPhonenumberInternational } from '@utils/common/contact-details.utils';
import { PractitionerProfileRouteState } from './principal-practitioner-profile.types';
import { useOnlineStatus } from '@hooks/useOnlineStatus';
import * as styles from './principal-practitioner-profile.styles';
import ROUTES from '@routes/routes';
import { PhoneIcon, XCircleIcon } from '@heroicons/react/solid';
import { CreateNote } from './components/create-note/create-note';
import { getLastNoteDate } from '@utils/child/child-profile-utils';
import { notesSelectors } from '@store/notes';
import { useSelector } from 'react-redux';
import { practitionerSelectors } from '@/store/practitioner';
import { classroomsSelectors } from '@/store/classroom';
import { authSelectors } from '@/store/auth';
import { PractitionerNotRegistered } from './practitioner-not-registered/practitioner-not-registered';
import { ClassroomGroupService } from '@/services/ClassroomGroupService';
import { getMonthName } from '@utils/classroom/attendance/track-attendance-utils';
import {
  addDays,
  format,
  getMonth,
  isFriday,
  isPast,
  isSameDay,
  isToday,
  isWeekend,
  nextMonday,
  sub,
} from 'date-fns';
import { PractitionerService } from '@/services/PractitionerService';
import EditRemovePractitionerFromProgrammePrompt from './components/remove-practitioner-from-programme/edit-remove-practitioner-from-programme-prompt';
import { formatDateLong } from '@/utils/common/date.utils';
import { AbsenteeDto } from '@ecdlink/core/lib/models/dto/Users/absentee.dto';
import { AbsenceCard } from './components/absence-card/absence-card';
import { AbsencesView } from './components/absences-view/absences-view';

export const PrincipalPractitionerProfileInfo: React.FC = () => {
  const dialog = useDialog();
  const history = useHistory();
  const userAuth = useSelector(authSelectors.getAuthUser);
  const { isOnline } = useOnlineStatus();
  const location = useLocation<PractitionerProfileRouteState>();
  const practitionerUserId = location.state.practitionerId;
  const classroom = useSelector(classroomsSelectors?.getClassroom);
  const classroomGroups = useSelector(classroomsSelectors.getClassroomGroups);
  const practitioners = useSelector(practitionerSelectors.getPractitioners);
  const practitioner = practitioners?.find(
    (practitioner) => practitioner?.userId === practitionerUserId
  );
  const daysAbsentLastMonth = practitioner?.daysAbsentLastMonth;
  const practitionerUser = useSelector(practitionerSelectors.getPractitioner);

  const practitionerAbsentees = practitioner?.absentees;

  const validAbsentee = practitionerAbsentees?.filter(
    (item) =>
      (!isPast(new Date(item?.absentDateEnd as string)) ||
        isToday(new Date(item?.absentDate as string))) &&
      item?.reason !== 'Practitioner removed from programme'
  );

  const currentDates = validAbsentee?.map((item) => {
    return item?.absentDate as string;
  });

  const orderedDates = currentDates?.sort(function (a, b) {
    return Date.parse(a) - Date.parse(b);
  });

  const currentAbsentee = validAbsentee?.find(
    (item) => item?.absentDate === orderedDates?.[0]
  ) as AbsenteeDto;
  const allAbsenteeClasses = practitionerAbsentees?.filter(
    (item) =>
      item?.absentDate === currentAbsentee?.absentDate &&
      item?.reason !== 'Practitioner removed from programme'
  );

  const classesWithAbsence =
    validAbsentee &&
    Object.values(
      validAbsentee?.reduce(
        (acc, obj) => ({ ...acc, [obj.absentDate as string]: obj }),
        {}
      )
    );

  classesWithAbsence?.sort(function (a, b) {
    return a?.absentDate?.localeCompare(b?.absentDate);
  });

  const isLeave = useMemo(
    () => currentAbsentee?.absentDate !== currentAbsentee?.absentDateEnd,
    [currentAbsentee?.absentDate, currentAbsentee?.absentDateEnd]
  );

  const isOnLeave =
    isLeave &&
    (isPast(new Date(currentAbsentee?.absentDate as string)) ||
      isToday(new Date(currentAbsentee?.absentDate as string))) &&
    !isPast(new Date(currentAbsentee?.absentDateEnd as string));

  const absenceIsToday = isSameDay(
    new Date(),
    new Date(currentAbsentee?.absentDate || '')
  );

  const lastMonth = sub(new Date(), {
    months: 1,
  });
  const [showAbsences, setShowAbsences] = useState(false);

  const practitionerClassroomGroups = classroomGroups?.filter((item) => {
    return item?.userId === practitionerUserId;
  });
  const { theme } = useTheme();

  const [createPractitionerNoteVisible, setCreatePractitionerdNoteVisible] =
    useState<boolean>(false);
  const notes = useSelector(
    notesSelectors.getNotesByUserId(practitionerUserId)
  );

  const onCreatePractitionerNoteBack = () => {
    setCreatePractitionerdNoteVisible(false);
  };
  const [childrenCount, setChildrenCount] = useState(0);
  const [classMetrics, setClassMetrics] = useState<ClassroomMetricReport[]>([]);

  const handleReassignClass = useCallback(
    (practitionerId: string, allAbsenteeClasses?: AbsenteeDto[]) => {
      const isPrincipal = practitioner?.isPrincipal;

      if (isPrincipal) {
        if (allAbsenteeClasses) {
          history.push('practitioner-reassign-class', {
            practitionerId,
            allAbsenteeClasses,
            principalPractitioner: practitionerUser,
          });
          return;
        }
        if (allAbsenteeClasses)
          history.push('practitioner-reassign-class', {
            practitionerId,
            allAbsenteeClasses,
            // principalPractitioner: practitionerUser,
          });

        return;
      }

      if (allAbsenteeClasses) {
        history.push('practitioner-reassign-class', {
          practitionerId,
          allAbsenteeClasses,
        });
        return;
      }

      history.push('practitioner-reassign-class', {
        practitionerId,
      });
    },
    [history, practitionerUser]
  );

  const handleComebackDay = useCallback((date: Date) => {
    if (isFriday(new Date(date)) || isWeekend(new Date(date))) {
      return nextMonday(new Date(date));
    }

    return new Date(addDays(new Date(date), 1));
  }, []);

  const handleAbsenceModal = useCallback(
    (item: AbsenteeDto) => {
      const absenceClasses = validAbsentee?.filter(
        (absence) => absence?.absenteeId === item?.absenteeId
      );

      dialog({
        position: DialogPosition.Middle,
        render: (onSubmit, onCancel) => (
          <ActionModal
            icon={'InformationCircleIcon'}
            iconColor="alertMain"
            iconBorderColor="alertBg"
            importantText={`What would you like to edit?`}
            actionButtons={[
              {
                text: 'Edit this absence',
                textColour: 'white',
                colour: 'primary',
                type: 'filled',
                onClick: () => {
                  handleReassignClass(practitionerUserId, absenceClasses);
                  onSubmit();
                },
                leadingIcon: 'PencilAltIcon',
              },
              {
                text: 'Add a new leave/absence',
                textColour: 'primary',
                colour: 'primary',
                type: 'outlined',
                onClick: () => {
                  handleReassignClass(practitionerUserId);
                  onSubmit();
                },
                leadingIcon: 'PlusIcon',
              },
            ]}
          />
        ),
      });
    },
    [dialog, handleReassignClass, practitionerUserId, validAbsentee]
  );

  useEffect(() => {
    if (!!classMetrics && !!classMetrics?.length) {
      const totalChildCount = classMetrics.reduce((total, currentItem) => {
        return total + currentItem.childCount;
      }, 0);

      setChildrenCount(totalChildCount);
    }
  }, [classMetrics, setChildrenCount]);

  const callForHelp = () => {
    window.open(`tel:${practitioner?.user?.phoneNumber}`);
  };

  const whatsapp = () => {
    window.open(
      `https://wa.me/${formatPhonenumberInternational(
        practitioner?.user?.phoneNumber ?? ''
      )}`
    );
  };

  const [editRemovalDialogVisable, setEditRemovalDialogVisable] =
    useState<boolean>(false);
  const [existingRemoval, setExistingRemoval] = useState<
    PractitionerRemovalHistory | undefined
  >();

  const getRemovalForPractitioner = async () => {
    const removalDetails = await new PractitionerService(
      userAuth?.auth_token!
    ).getRemovalForPractitioner(practitioner?.userId!);
    setExistingRemoval(removalDetails);

    return removalDetails;
  };

  const classroomsMetrics = async () => {
    const today = new Date();
    const firstDayPrevMonth = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    const lastDayPrevMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    const metricsData = await new ClassroomGroupService(
      userAuth?.auth_token!
    ).getClassAttendanceMetricsByUser(
      practitionerUserId,
      firstDayPrevMonth,
      lastDayPrevMonth
    );
    setClassMetrics(metricsData);
    return metricsData;
  };

  useEffect(() => {
    classroomsMetrics();
    getRemovalForPractitioner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelPractitionerRemoval = async () => {
    await new PractitionerService(
      userAuth?.auth_token || ''
    ).cancelRemovePractitionerFromProgramme(existingRemoval?.id);
    setExistingRemoval(undefined);
  };

  const notificationItem: MenuListDataItem[] = [
    {
      showIcon: true,
      menuIcon: 'ExclamationIcon',
      menuIconClassName: 'border-0',
      iconColor: 'white',
      title: `${daysAbsentLastMonth} ${
        daysAbsentLastMonth && Number(daysAbsentLastMonth) > 1 ? 'days' : 'day'
      } absent last month`,
      titleStyle: 'text-textDark semibold whitespace-normal',
      subTitle: format(lastMonth, 'MMMM yyyy'),
      subTitleStyle: 'text-textMid',
      iconBackgroundColor: 'alertMain',
      backgroundColor: 'alertBg',
      onActionClick: () => setShowAbsences(true),
    },
  ];

  return (
    <>
      {practitioner?.isRegistered === null ||
      practitioner?.isRegistered === false ? (
        <PractitionerNotRegistered
          practitioner={practitioner}
          classroom={classroom}
          existingRemoval={existingRemoval}
        />
      ) : (
        <div className={styles.contentWrapper}>
          <BannerWrapper
            showBackground={true}
            backgroundUrl={theme?.images.graphicOverlayUrl}
            title={`${practitioner?.user?.firstName}'s Profile`}
            color={'primary'}
            size="medium"
            renderBorder={true}
            renderOverflow={false}
            onBack={() =>
              history.push(ROUTES.CLASSROOM.ROOT, { activeTabIndex: 1 })
            }
            displayOffline={!isOnline}
          >
            <div className={styles.avatarWrapper}>
              <ProfileAvatar
                hasConsent={true}
                canChangeImage={false}
                dataUrl={practitioner?.user?.profileImageUrl || ''}
                size={'header'}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onPressed={() => {}}
              />
            </div>

            <div className={styles.chipsWrapper}>
              {practitionerClassroomGroups?.length > 0 ? (
                practitionerClassroomGroups?.map((item, index) => {
                  return (
                    <StatusChip
                      key={index}
                      backgroundColour="primary"
                      borderColour="primary"
                      text={`${item?.name}` || 'No class'}
                      textColour={'white'}
                      className={'px-3 py-1.5'}
                    />
                  );
                })
              ) : (
                <StatusChip
                  backgroundColour="primary"
                  borderColour="primary"
                  text={'No class'}
                  textColour={'white'}
                  className={'px-3 py-1.5'}
                />
              )}
              {!!childrenCount && (
                <StatusChip
                  backgroundColour="secondary"
                  borderColour="secondary"
                  text={`${childrenCount} children`}
                  textColour={'white'}
                  className={'mr-2 px-3 py-1.5'}
                />
              )}
            </div>
            <div className={styles.contactButtons}>
              <Button
                color={'primary'}
                type={'outlined'}
                className={'rounded-2xl'}
                size={'small'}
                onClick={callForHelp}
              >
                <PhoneIcon
                  className="text-primary h-5 w-5"
                  aria-hidden="true"
                />
              </Button>
              <Button
                color={'primary'}
                type={'outlined'}
                className={'rounded-2xl'}
                size={'small'}
                onClick={whatsapp}
              >
                <img
                  src={getLogo(LogoSvgs.whatsapp)}
                  alt="whatsapp"
                  className={styles.buttonIconStyle}
                />
              </Button>
            </div>
          </BannerWrapper>
          <div className="flex flex-wrap justify-center">
            {existingRemoval && (
              <Card className={styles.removalCard}>
                <div className="mt-2 mr-4 mb-2 flex items-center">
                  <div className="mx-4 mt-2 mb-4 flex w-full items-center">
                    <XCircleIcon
                      className="text-errorMain mt-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    <Typography
                      type={'body'}
                      color="errorMain"
                      text={`${
                        practitioner?.user?.firstName
                      } will be removed on ${
                        existingRemoval?.dateOfRemoval
                          ? formatDateLong(
                              new Date(existingRemoval?.dateOfRemoval)
                            )
                          : ''
                      }`}
                      className={'text-errorMain ml-4 mt-2'}
                    />
                  </div>
                  <Button
                    size="small"
                    shape="normal"
                    color="primary"
                    type="filled"
                    onClick={() => setEditRemovalDialogVisable(true)}
                  >
                    {renderIcon('PencilIcon', 'w-5 h-5 mr-2')}
                    <Typography
                      type="body"
                      className="mr-4"
                      color="white"
                      text={'Edit'}
                    ></Typography>
                  </Button>
                </div>
              </Card>
            )}
            {!currentAbsentee &&
              !!daysAbsentLastMonth &&
              Number(daysAbsentLastMonth) > 0 && (
                <div className="my-4 flex w-11/12 justify-center">
                  <StackedList
                    isFullHeight={false}
                    className={'flex w-11/12 flex-col gap-2'}
                    listItems={notificationItem}
                    type={'MenuList'}
                  />
                </div>
              )}
            {!existingRemoval && (
              <AbsenceCard
                absenceIsToday={absenceIsToday}
                currentAbsentee={currentAbsentee}
                handleComebackDay={handleComebackDay}
                practitioner={practitioner!}
                isOnLeave={isOnLeave}
                handleReassignClass={handleReassignClass}
                handleAbsenceModal={(item: AbsenteeDto) =>
                  handleAbsenceModal(item)
                }
                isLeave={isLeave}
                allAbsenteeClasses={allAbsenteeClasses}
                practitionerUserId={practitionerUserId}
                classesWithAbsence={classesWithAbsence}
                practitionerAbsentees={practitionerAbsentees}
              />
            )}

            {!!classMetrics && !!classMetrics.length
              ? classMetrics?.map((item, index) => {
                  const classroomGroup = practitionerClassroomGroups?.find(
                    (x) => {
                      return x?.id === item?.classroomGroupId;
                    }
                  );
                  return (
                    <Card className={styles.absentCard} key={index}>
                      <Typography
                        type={'h1'}
                        text={classroomGroup?.name}
                        color={'textMid'}
                        className={styles.absentCardTitle}
                      />
                      <div>
                        <div className="mt-2 mr-2 flex flex-col">
                          <div className="ml-4 flex w-11/12 items-center justify-between">
                            <div className="flex w-full items-center">
                              <Typography
                                type={'h2'}
                                text={`${item?.childCount}`}
                                color={'textDark'}
                                className="mt-2"
                              />
                              <Typography
                                type={'body'}
                                text={'children in class'}
                                color={'textDark'}
                                className="mt-2 ml-4 mr-4"
                              />
                            </div>
                            <Button
                              size="small"
                              shape="normal"
                              color="primary"
                              type="filled"
                              onClick={() =>
                                history.push(
                                  ROUTES.PRINCIPAL.PRACTITIONER_CHILD_LIST,
                                  {
                                    practitionerUserId,
                                    classroomGroup,
                                  }
                                )
                              }
                              className="mt-2 rounded-xl"
                            >
                              <Typography
                                type="help"
                                color="white"
                                text="View"
                              />
                              {renderIcon('EyeIcon', styles.buttonIcon)}
                            </Button>
                          </div>
                          <div className="mx-4 mt-2 mb-4 flex w-9/12 items-center justify-start">
                            <StatusChip
                              backgroundColour="alertMain"
                              borderColour="alertMain"
                              text={`${item?.attendancePercentage}%`}
                              textColour={'white'}
                              className={'mr-2'}
                            />
                            <Typography
                              type={'body'}
                              weight={'bold'}
                              text={`attendance in ${getMonthName(
                                getMonth(new Date())
                                // eslint-disable-next-line no-useless-concat
                              )}\u00A0${item?.year}`}
                              color={'textMid'}
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                  );
                })
              : null}
            <Card className={styles.absentCard}>
              <Typography
                type={'h1'}
                text={'Progress summary'}
                color={'textMid'}
                className={styles.absentCardTitle}
              />
              <div className="mt-2 mr-4 flex items-center">
                <div className="mx-4 mt-2 mb-4 flex w-full items-center">
                  <StatusChip
                    backgroundColour="errorMain"
                    borderColour="errorMain"
                    text={'N/A'}
                    textColour={'white'}
                    className={'mr-2'}
                  />
                  <Typography
                    type={'body'}
                    weight={'bold'}
                    text={'children working on: does simple things when asked '}
                    color={'textMid'}
                  />
                </div>
                <Button
                  size="small"
                  shape="normal"
                  color="primary"
                  type="filled"
                  onClick={() => {}}
                  className="rounded-xl"
                  disabled={true}
                >
                  <Typography type="help" color="white" text="View" />
                  {renderIcon('EyeIcon', styles.buttonIcon)}
                </Button>
              </div>
            </Card>
            <Card className={styles.absentCard}>
              <Typography
                type={'h1'}
                text={'Programme planning'}
                color={'textMid'}
                className={styles.absentCardTitle}
              />
              <div>
                <div className="mt-2 mr-4 flex flex-col">
                  <div className="ml-4 flex w-11/12 items-center">
                    <Typography
                      type={'h2'}
                      text={'N/A'}
                      color={'textDark'}
                      className="mt-2"
                    />
                    <Typography
                      type={'body'}
                      text={`programmes planned in  ${getMonthName(
                        getMonth(new Date()) - 1
                        // eslint-disable-next-line no-useless-concat
                      )} 2022`}
                      color={'textDark'}
                      className="mt-2 ml-4 mr-8"
                    />
                    <Button
                      size="small"
                      shape="normal"
                      color="primary"
                      type="filled"
                      onClick={() => {}}
                      className="mt-2 rounded-xl"
                      disabled={true}
                    >
                      <Typography type="help" color="white" text="View" />
                      {renderIcon('EyeIcon', styles.buttonIcon)}
                    </Button>
                  </div>
                  <div className="mx-4 mt-2 mb-4 flex w-9/12 items-center justify-start">
                    <StatusChip
                      backgroundColour="errorMain"
                      borderColour="errorMain"
                      text={'N/A'}
                      textColour={'white'}
                      className={'mr-2'}
                    />
                    <Typography
                      type={'body'}
                      weight={'bold'}
                      text={'skill missing: walking & moving'}
                      color={'textMid'}
                    />
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <>
            <div className={styles.infoWrapper}>
              <div>
                <Typography
                  text={'Cellphone number'}
                  type="h5"
                  color="textMid"
                  className={'mt-4'}
                />
                <Typography
                  text={practitioner?.user?.phoneNumber}
                  type="h4"
                  color="textDark"
                  className={'mt-1'}
                />
              </div>
              <div>
                <Button
                  size="small"
                  shape="normal"
                  color="primary"
                  type="outlined"
                  onClick={() => {
                    //TODO: what if copy fails?
                    navigator?.clipboard?.writeText &&
                      navigator?.clipboard?.writeText(
                        practitioner?.user?.phoneNumber!
                      );
                  }}
                >
                  <Typography type="help" color="primary" text="Copy" />
                  {renderIcon('DocumentDuplicateIcon', styles.buttonIcon)}
                </Button>
              </div>
            </div>
            <Divider dividerType="dashed" className="my-4" />
            <div className={styles.infoWrapper}>
              <div>
                <Typography
                  text={'Email address'}
                  type="h5"
                  color="textMid"
                  className={'mt-1'}
                />
                <Typography
                  text={practitioner?.user?.email}
                  type="h4"
                  color="textDark"
                  className={'mt-1'}
                />
              </div>
              <div>
                <Button
                  size="small"
                  shape="normal"
                  color="primary"
                  type="outlined"
                  onClick={() => {
                    //TODO: what if copy fails?
                    navigator?.clipboard?.writeText &&
                      navigator?.clipboard?.writeText(
                        practitioner?.user?.email!
                      );
                  }}
                >
                  <Typography type="help" color="primary" text="Copy" />
                  {renderIcon('DocumentDuplicateIcon', styles.buttonIcon)}
                </Button>
              </div>
            </div>
            <Divider dividerType="dashed" className="my-4" />
            <div className={styles.infoWrapper}>
              <div>
                <Typography
                  text={'Smartstart club'}
                  type="h5"
                  color="textMid"
                  className={'mt-1'}
                />
                <Typography
                  text={'N/A'}
                  type="h4"
                  color="textDark"
                  className={'mt-1'}
                />
              </div>
            </div>
            <Divider dividerType="dashed" className="my-4" />
            <div className={styles.infoWrapper}>
              <div>
                <Typography
                  text={'Your notes'}
                  type="h5"
                  color="textMid"
                  className={'mt-1'}
                />
                {notes?.length > 0 ? (
                  <Typography
                    text={getLastNoteDate(notes)}
                    type="h4"
                    color="textDark"
                    className={'mt-1'}
                  />
                ) : (
                  <Typography
                    text={''}
                    type="h4"
                    color="textDark"
                    className={'mt-1'}
                  />
                )}
              </div>
              <div>
                <Button
                  size="small"
                  shape="normal"
                  color="primary"
                  type="filled"
                  onClick={
                    () =>
                      history.push(ROUTES.PRINCIPAL.NOTES, {
                        practitionerId: practitionerUserId,
                      })
                    // setCreatePractitionerdNoteVisible(true)
                  }
                >
                  {renderIcon('EyeIcon', styles.buttonIcon)}
                  <Typography
                    type="help"
                    color="white"
                    text="View"
                    className="ml-1"
                  />
                </Button>
              </div>
              <Dialog
                fullScreen
                visible={createPractitionerNoteVisible}
                position={DialogPosition.Middle}
              >
                <div className={styles.dialogContent}>
                  <CreateNote
                    userId={practitionerUserId || ''}
                    noteType={NoteTypeEnum.Unknown}
                    titleText={`Add a note to ${practitioner?.user?.firstName} profile`}
                    onBack={() => onCreatePractitionerNoteBack()}
                    onCreated={() => onCreatePractitionerNoteBack()}
                  />
                </div>
              </Dialog>
            </div>
            <Divider dividerType="dashed" className="my-4" />
            {!existingRemoval && (
              <div className="flex w-full justify-center">
                <Button
                  type="outlined"
                  color="primary"
                  className={'mt-6 mb-6 w-11/12'}
                  onClick={() =>
                    history.push(
                      ROUTES.PRINCIPAL.PRACTITIONER_REMOVE_FROM_PROGRAMME,
                      {
                        practitionerId: practitionerUserId,
                      }
                    )
                  }
                >
                  {renderIcon(
                    'UsersIcon',
                    'w-5 h-5 color-primary text-primary mr-2'
                  )}
                  <Typography
                    type="body"
                    className="mr-4"
                    color="primary"
                    text={'Remove practitioner'}
                  ></Typography>
                </Button>
              </div>
            )}
          </>
        </div>
      )}
      <Dialog
        stretch={true}
        visible={showAbsences}
        position={DialogPosition.Full}
      >
        <AbsencesView
          practitioner={practitioner!}
          setShowAbsences={setShowAbsences}
          lastMonth={lastMonth}
          absences={practitionerAbsentees}
        />
      </Dialog>
      <Dialog
        className={'mb-16 px-4'}
        stretch={true}
        visible={editRemovalDialogVisable}
        position={DialogPosition.Middle}
      >
        <EditRemovePractitionerFromProgrammePrompt
          practitioner={practitioner}
          classroomName={classroom?.name || ''}
          removalDetails={existingRemoval as PractitionerRemovalHistory}
          onEdit={() => {
            history.push(ROUTES.PRINCIPAL.PRACTITIONER_REMOVE_FROM_PROGRAMME, {
              practitionerId: practitionerUserId,
            });
          }}
          onCancel={() => {
            cancelPractitionerRemoval();
            setEditRemovalDialogVisable(false);
            history.push(ROUTES.CLASSROOM.ROOT, { activeTabIndex: 1 });
          }}
          onClose={() => {
            setEditRemovalDialogVisable(false);
          }}
        />
      </Dialog>
    </>
  );
};
