import {
  ContentConsentTypeEnum,
  ProgrammeTypeDto,
  ProvinceDto,
} from '@ecdlink/core';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Button,
  ButtonGroup,
  Typography,
  ButtonGroupTypes,
  renderIcon,
  BannerWrapper,
  Checkbox,
  ImageInput,
  Dialog,
  DialogPosition,
  classNames,
  FormInput,
  Dropdown,
} from '@ecdlink/ui';
import { useEffect, useState } from 'react';
import { useForm, useFormState, useWatch, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { staticDataSelectors } from '@store/static-data';
import * as styles from './programme-details.styles';
import { useOnlineStatus } from '@/hooks/useOnlineStatus';
import Article from '@/components/article/article';
import {
  ProgrammeDetailsModel,
  ProgrammeDetailsSchema,
} from '@/schemas/trainee/programme-details';
import { PhotoPrompt } from '@/components/photo-prompt/photo-prompt';
import {
  SmartSpaceChecklisstStepsSteps,
  SmartSpaceChecklistProps,
  yesNoOptions,
} from '../../smart-space-checklist.types';
import { traineeSelectors } from '@/store/trainee';
import { AddressMap } from '../map/map';
import tool_R4c_form from '@/assets/tool_R4c_form.pdf';
import tool_R4b_form from '@/assets/tool_R4b_form.pdf';
import tool_R4a_form from '@/assets/tool_R4a_form.pdf';

export const ProgrammeDetails: React.FC<SmartSpaceChecklistProps> = ({
  setActiveStep,
  handleNextSection,
  visitId,
}) => {
  const visitSection = 'Programme details';

  const {
    getValues: getProgrammeFormValues,
    setValue: setProgrammeFormValue,
    register: programmeFormRegister,
    trigger: triggerR4bForm,
    control: programmeFormControl,
  } = useForm<ProgrammeDetailsModel>({
    resolver: yupResolver(ProgrammeDetailsSchema),
    shouldUnregister: true,
    mode: 'onChange',
  });

  const { isOnline } = useOnlineStatus();
  const { isValid } = useFormState({ control: programmeFormControl });
  const {
    haveTheTitleDeeds,
    ownTheProperty,
    unproclaimedLand,
    liveAtTheProperty,
  } = useWatch<ProgrammeDetailsModel>({
    control: programmeFormControl,
    defaultValue: {},
  });
  const provinces = useSelector(staticDataSelectors.getProvinces);
  const [articleTitle, setArticleTitle] = useState<string>();
  const [presentArticle, setPresentArticle] = useState<boolean>(false);
  const [contentConsentTypeEnum, setContentConsentTypeEnum] =
    useState<ContentConsentTypeEnum>();
  const [r4bPhotoUrl, setR4bPhotoUrl] = useState<string>();
  const [photoActionBarVisible, setPhotoActionBarVisible] =
    useState<boolean>(false);
  const acceptedFormats = ['jpg, bmp'];
  const visitData = useSelector(traineeSelectors.getTraineeVisitData(visitId));
  const [showMap, setShowMap] = useState(false);
  const [displayPhotoDeleteWarning, setDisplayPhotoDeleteWarning] =
    useState<boolean>(false);
  const disableSection = true;
  const [questions, setAnswers] = useState([
    {
      question:
        'I have read the SmartStarter Information Sheet on Child Protection and Confidentiality',
      answer: false,
    },
    {
      question: 'What is the name of your programme?',
      answer: '',
    },
    {
      question: ' What type of programme are you running or planning to run?',
      answer: '',
    },
    {
      question: 'Flat/ unit / apartment number',
      answer: '',
    },
    {
      question: 'Street address',
      answer: '',
    },
    {
      question: 'Suburb/area',
      answer: '',
    },
    {
      question: 'City',
      answer: '',
    },
    {
      question: 'Province',
      answer: '',
    },
    {
      question: 'Postal Code',
      answer: '',
    },
    {
      question:
        'Do you own the property where you will run your SmartStart programme?',
      answer: '',
    },
    {
      question: 'Do you have the Title Deeds for the property?',
      answer: '',
    },
    {
      question: 'Is the property on un-proclaimed land?',
      answer: '',
    },
    {
      question: 'Take a photo of a certified copy of the Title Deeds',
      answer: '',
    },
    {
      question:
        'Take a photo of the filled and signed R4c Affidavit - Property Ownership',
      answer: '',
    },
    {
      question: 'Do you live at the property?',
      answer: '',
    },
    {
      question:
        'Take a photo of the filled and signed R4a form - Agreement for premises to be uesd for early learning programme.',
      answer: '',
    },
    {
      question:
        'Take a photo of the filled and signed R4b form - Confirmation of Lease.',
      answer: '',
    },
  ]);

  const checkedquestion = (question: string) => {
    const isChecked = visitData?.find((item) => item?.question === question);
    return isChecked;
  };

  const programData = useSelector(staticDataSelectors.getProgrammeTypes);

  const displayArticle = async (key: ContentConsentTypeEnum, title: string) => {
    setContentConsentTypeEnum(key);
    setPresentArticle(true);
    setArticleTitle(title);
  };

  const setPhotoUrl = (imageUrl: string) => {
    setProgrammeFormValue('r4bPhoto', imageUrl);

    setR4bPhotoUrl(imageUrl);
    triggerR4bForm();
    setPhotoActionBarVisible(false);
  };

  const deleteBirthDocumentPhoto = () => {
    setProgrammeFormValue('r4bPhoto', '');
    setR4bPhotoUrl('');
    setDisplayPhotoDeleteWarning(false);
  };

  useEffect(() => {
    if (checkedquestion(questions[0].question)?.questionAnswer) {
      setProgrammeFormValue('haveReadTheSmartStarterInformation', true);
    }

    if (checkedquestion(questions[1].question)?.questionAnswer) {
      setProgrammeFormValue(
        'programmeName',
        checkedquestion(questions[1].question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions[2].question)?.questionAnswer) {
      setProgrammeFormValue(
        'programmeType',
        checkedquestion(questions[2].question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions[3].question)?.questionAnswer) {
      setProgrammeFormValue(
        'ward',
        checkedquestion(questions[3].question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions[4].question)?.questionAnswer) {
      setProgrammeFormValue(
        'addressLine1',
        checkedquestion(questions[4].question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions[5].question)?.questionAnswer) {
      setProgrammeFormValue(
        'addressLine2',
        checkedquestion(questions[5].question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions[6].question)?.questionAnswer) {
      setProgrammeFormValue(
        'addressLine3',
        checkedquestion(questions[6].question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions[7].question)?.questionAnswer) {
      setProgrammeFormValue(
        'provinceId',
        checkedquestion(questions[7].question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions[8].question)?.questionAnswer) {
      setProgrammeFormValue(
        'postalCode',
        checkedquestion(questions[8].question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions[9].question)?.questionAnswer) {
      const answer =
        checkedquestion(questions[9].question)?.questionAnswer === 'false'
          ? false
          : true;
      setProgrammeFormValue('ownTheProperty', answer);
    }

    if (checkedquestion(questions[10]?.question)?.questionAnswer) {
      const answer =
        checkedquestion(questions[10].question)?.questionAnswer === 'false'
          ? false
          : true;
      setProgrammeFormValue('haveTheTitleDeeds', answer);
    }

    if (checkedquestion(questions[11]?.question)?.questionAnswer) {
      setProgrammeFormValue(
        'unproclaimedLand',
        Boolean(checkedquestion(questions[11].question)?.questionAnswer)
      );
    }

    if (checkedquestion(questions[12]?.question)?.questionAnswer) {
      setProgrammeFormValue(
        'r4bPhoto',
        checkedquestion(questions[12]?.question)?.questionAnswer!
      );

      setR4bPhotoUrl(checkedquestion(questions[12]?.question)?.questionAnswer!);
    }

    if (checkedquestion(questions[13]?.question)?.questionAnswer) {
      setProgrammeFormValue(
        'r4bPhoto',
        checkedquestion(questions[13]?.question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions[14]?.question)?.questionAnswer) {
      const answer =
        checkedquestion(questions[14].question)?.questionAnswer === 'false'
          ? false
          : true;
      setProgrammeFormValue('liveAtTheProperty', answer);
    }
    if (checkedquestion(questions[15]?.question)?.questionAnswer) {
      setProgrammeFormValue(
        'r4bPhoto',
        checkedquestion(questions[15].question)?.questionAnswer!
      );
    }
    if (checkedquestion(questions[16]?.question)?.questionAnswer) {
      setProgrammeFormValue(
        'r4bPhoto',
        checkedquestion(questions[16]?.question)?.questionAnswer!
      );
    }

    if (checkedquestion(questions[17]?.question)?.questionAnswer) {
      setProgrammeFormValue(
        'r4bPhoto',
        checkedquestion(questions[17].question)?.questionAnswer!
      );
    }
  }, []);

  useEffect(() => {
    if (ownTheProperty === false) {
      setProgrammeFormValue('haveTheTitleDeeds', false);
    }
  }, [ownTheProperty, setProgrammeFormValue]);

  useEffect(() => {
    if (ownTheProperty === true) {
      setProgrammeFormValue('liveAtTheProperty', undefined);
    }
  }, [ownTheProperty, setProgrammeFormValue]);

  const onDownloadImageR4c = () => {
    const pdfUrl = tool_R4c_form;
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.setAttribute('download', 'r4b_form.pdf');
    document.body.appendChild(link);
    link.click();
  };

  const onDownloadImageR4b = () => {
    const pdfUrl = tool_R4b_form;
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.setAttribute('download', 'r4b_form.pdf');
    document.body.appendChild(link);
    link.click();
  };

  const onDownloadImageR4a = () => {
    const pdfUrl = tool_R4a_form;
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.setAttribute('download', 'r4a_form.pdf');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <BannerWrapper
        showBackground={false}
        size="medium"
        renderBorder={true}
        title={'Programme details'}
        subTitle={'Step 1 of 4'}
        color={'primary'}
        onBack={() => setActiveStep(SmartSpaceChecklisstStepsSteps.INITIAL)}
        displayOffline={!isOnline}
        renderOverflow={true}
      >
        <div
          className={`${
            disableSection ? 'pointer-events-none p-4 opacity-50' : ''
          }`}
        >
          <div>
            <Typography
              type={'h2'}
              text={'Programme details'}
              color={'textDark'}
              className={'my-3'}
            />
            {disableSection && (
              <Alert
                className="my-4"
                type="warning"
                title="You are viewing this form and cannot edit responses."
                list={['This form should be filled in by the trainee.']}
              />
            )}
            <Typography
              type={'h4'}
              text={'Child protection and confidentiality'}
              color={'textDark'}
              className={'my-3'}
            />
            <div className="flex items-center">
              <div className="'flex items-center' w-full flex-row justify-start">
                <div
                  className="flex items-start gap-2"
                  onClick={() => {
                    setProgrammeFormValue(
                      'haveReadTheSmartStarterInformation',
                      !getProgrammeFormValues()
                        .haveReadTheSmartStarterInformation
                    );
                  }}
                >
                  <Checkbox<ProgrammeDetailsModel>
                    checked={
                      Boolean(
                        checkedquestion(questions?.[0].question)?.questionAnswer
                      ) ||
                      getProgrammeFormValues()
                        .haveReadTheSmartStarterInformation
                    }
                    register={programmeFormRegister}
                    nameProp={'haveReadTheSmartStarterInformation'}
                    onCheckboxChange={() => {}}
                    value={
                      String(
                        getProgrammeFormValues()
                          .haveReadTheSmartStarterInformation
                      ) ||
                      checkedquestion(questions?.[0].question)?.questionAnswer!
                    }
                    disabled={Boolean(checkedquestion(questions?.[0].question))}
                  ></Checkbox>
                  <Typography
                    text={questions?.[0].question}
                    type="body"
                    color={'textMid'}
                  />
                </div>
              </div>

              <Typography
                type={'body'}
                text={'View'}
                color={'secondary'}
                className={'my-3'}
                underline={true}
                onClick={() => {
                  displayArticle(
                    ContentConsentTypeEnum.ChildProtection,
                    'Child Protection'
                  );
                }}
              />
            </div>
          </div>
          <div className="mt-2 space-y-4">
            <>
              <FormInput<ProgrammeDetailsModel>
                label={questions?.[1].question}
                register={programmeFormRegister}
                nameProp={'programmeName'}
                placeholder={'E.g. Little Lambs Preschool'}
                type={'text'}
                onChange={(e) => {}}
                disabled={Boolean(checkedquestion(questions?.[1].question))}
              ></FormInput>

              <div
                className={`w-full ${
                  Boolean(checkedquestion(questions?.[2].question))
                    ? 'pointer-events-none'
                    : ''
                }`}
              >
                <label className={styles.label}>
                  {questions?.[2].question}
                </label>
                <div className="mt-1">
                  <Controller
                    name={'programmeType'}
                    control={programmeFormControl}
                    render={({ field: { onChange, value, ref } }) => (
                      <ButtonGroup<string | string[]>
                        inputRef={ref}
                        options={
                          (programData &&
                            programData.map((x: ProgrammeTypeDto) => {
                              return { text: x.description, value: x.id ?? '' };
                            })) ||
                          []
                        }
                        multiple={false}
                        onOptionSelected={(e) => {
                          setProgrammeFormValue('programmeType', e as string);
                        }}
                        selectedOptions={value}
                        color="secondary"
                        type={ButtonGroupTypes.Button}
                        className={'w-full'}
                      />
                    )}
                  ></Controller>
                </div>
              </div>

              <FormInput<ProgrammeDetailsModel>
                label={questions?.[3].question}
                register={programmeFormRegister}
                nameProp={'addressLine1'}
                placeholder={'203 Oak Apartments'}
                type={'text'}
                onChange={(e) => {}}
                disabled={Boolean(checkedquestion(questions?.[3].question))}
              ></FormInput>

              <FormInput<ProgrammeDetailsModel>
                label={questions?.[4].question}
                register={programmeFormRegister}
                nameProp={'addressLine1'}
                placeholder={'E.g. 11 Green Road'}
                type={'text'}
                onChange={(e) => {}}
                disabled={Boolean(checkedquestion(questions?.[4].question))}
              ></FormInput>

              <FormInput<ProgrammeDetailsModel>
                label={questions?.[5].question}
                register={programmeFormRegister}
                nameProp={'addressLine2'}
                placeholder={'E.g. Mamelodi East'}
                type={'text'}
                onChange={(e) => {}}
                disabled={Boolean(checkedquestion(questions?.[5].question))}
              ></FormInput>

              <FormInput<ProgrammeDetailsModel>
                label={questions?.[6].question}
                register={programmeFormRegister}
                nameProp={'addressLine3'}
                placeholder={'E.g. Cape Town'}
                type={'text'}
                onChange={(e) => {}}
                disabled={Boolean(checkedquestion(questions?.[6].question))}
              ></FormInput>

              <Dropdown
                placeholder={'Choose province'}
                list={
                  (provinces &&
                    provinces.map((province: ProvinceDto) => ({
                      label: province.description,
                      value: province.id || '',
                    }))) ||
                  []
                }
                fillType="clear"
                fullWidth={true}
                label={'Province'}
                className={classNames(styles.divider, 'w-full')}
                selectedValue={getProgrammeFormValues().provinceId}
                onChange={(value: string) => {
                  setProgrammeFormValue('provinceId', value as string, {
                    shouldValidate: true,
                  });
                }}
              />

              <div className={'w-full'}>
                <FormInput<ProgrammeDetailsModel>
                  label={questions?.[8].question}
                  register={programmeFormRegister}
                  nameProp={'postalCode'}
                  placeholder={'E.g. 0081'}
                  type={'text'}
                  onChange={(e) => {}}
                  disabled={Boolean(checkedquestion(questions?.[8].question))}
                ></FormInput>
              </div>

              <div className={'w-full'}>
                <label className={styles.label}>
                  {questions?.[9].question}
                </label>
                <div
                  className={`mt-1 ${
                    Boolean(checkedquestion(questions?.[9].question))
                      ? 'pointer-events-none'
                      : ''
                  }`}
                >
                  <ButtonGroup<boolean | undefined>
                    options={yesNoOptions}
                    onOptionSelected={(value: any) => {
                      setProgrammeFormValue(
                        'ownTheProperty',
                        value as boolean,
                        {
                          shouldValidate: true,
                        }
                      );
                    }}
                    selectedOptions={[getProgrammeFormValues().ownTheProperty]}
                    color="secondary"
                    type={ButtonGroupTypes.Button}
                    className={'w-full'}
                  />
                </div>
              </div>

              {ownTheProperty === true && (
                <div className={'w-full'}>
                  <label className={styles.label}>
                    {questions?.[10].question}
                  </label>
                  <div
                    className={`mt-1 ${
                      Boolean(checkedquestion(questions?.[10].question))
                        ? 'pointer-events-none'
                        : ''
                    }`}
                  >
                    <ButtonGroup<boolean | undefined>
                      options={yesNoOptions}
                      onOptionSelected={(value: any) => {
                        setProgrammeFormValue(
                          'haveTheTitleDeeds',
                          value as boolean,
                          {
                            shouldValidate: true,
                          }
                        );
                      }}
                      selectedOptions={[
                        getProgrammeFormValues().haveTheTitleDeeds,
                      ]}
                      color="secondary"
                      type={ButtonGroupTypes.Button}
                      className={'w-full'}
                    />
                  </div>
                </div>
              )}
              {ownTheProperty === false && (
                <div className={'w-full'}>
                  <label className={styles.label}>
                    {questions?.[14].question}
                  </label>
                  <div
                    className={`mt-1 ${
                      Boolean(checkedquestion(questions?.[14].question))
                        ? 'pointer-events-none'
                        : ''
                    }`}
                  >
                    <ButtonGroup<boolean | undefined>
                      options={yesNoOptions}
                      onOptionSelected={(value: any) => {
                        setProgrammeFormValue(
                          'liveAtTheProperty',
                          value as boolean,
                          {
                            shouldValidate: true,
                          }
                        );
                      }}
                      selectedOptions={[
                        getProgrammeFormValues().liveAtTheProperty,
                      ]}
                      color="secondary"
                      type={ButtonGroupTypes.Button}
                      className={'w-full'}
                    />
                  </div>
                </div>
              )}
              {liveAtTheProperty === true && (
                <>
                  <Alert
                    className="mb-4"
                    type="info"
                    title={`The owner or landlord of the property will need to fill in the “confirmation of lease” with you (form R4b).`}
                    list={[
                      'Fill in the form with your landlord and upload a picture of it below.',
                    ]}
                    button={
                      <Button
                        onClick={onDownloadImageR4b}
                        text="Download the R4b form"
                        icon="DownloadIcon"
                        type={'filled'}
                        color={'primary'}
                        textColor={'white'}
                      />
                    }
                  />
                  <ImageInput<ProgrammeDetailsModel>
                    acceptedFormats={acceptedFormats}
                    label={questions?.[16].question}
                    nameProp="r4bPhoto"
                    icon="CameraIcon"
                    className={'py-4'}
                    currentImageString={r4bPhotoUrl}
                    register={programmeFormRegister}
                    overrideOnClick={() => setPhotoActionBarVisible(true)}
                    onValueChange={(imageString: string) => {
                      setProgrammeFormValue('r4bPhoto', imageString);
                      triggerR4bForm();
                    }}
                    disabled={Boolean(
                      checkedquestion(questions?.[16].question)
                    )}
                  ></ImageInput>
                </>
              )}
              {liveAtTheProperty === false && (
                <>
                  <Alert
                    className="mb-4"
                    type="info"
                    title={`The owner or landlord of the property will need to fill in the “Agreement for premises to be used for early learning programme” with you (form 4a).`}
                    list={[
                      'Fill in the form with your landlord and upload a picture of it below.',
                    ]}
                    button={
                      <Button
                        onClick={onDownloadImageR4a}
                        text="Download the R4a form"
                        icon="DownloadIcon"
                        type={'filled'}
                        color={'primary'}
                        textColor={'white'}
                      />
                    }
                  />
                  <ImageInput<ProgrammeDetailsModel>
                    acceptedFormats={acceptedFormats}
                    label={questions?.[15].question}
                    nameProp="r4bPhoto"
                    icon="CameraIcon"
                    className={'py-4'}
                    currentImageString={r4bPhotoUrl}
                    register={programmeFormRegister}
                    overrideOnClick={() => setPhotoActionBarVisible(true)}
                    onValueChange={(imageString: string) => {
                      setProgrammeFormValue('r4bPhoto', imageString);
                      triggerR4bForm();
                    }}
                    disabled={Boolean(
                      checkedquestion(questions?.[15].question)
                    )}
                  ></ImageInput>
                </>
              )}
            </>
            {haveTheTitleDeeds === false && ownTheProperty === true && (
              <div className={'w-full'}>
                <label className={styles.label}>
                  {questions?.[11].question}
                </label>
                <div
                  className={`mt-1 ${
                    Boolean(checkedquestion(questions?.[11].question))
                      ? 'pointer-events-none'
                      : ''
                  }`}
                >
                  <Controller
                    name="unproclaimedLand"
                    control={programmeFormControl}
                    render={({ field: { onChange, value, ref } }) => (
                      <ButtonGroup<boolean>
                        inputRef={ref}
                        options={yesNoOptions}
                        onOptionSelected={(value: any) => {
                          setProgrammeFormValue(
                            'unproclaimedLand',
                            value as boolean,
                            {
                              shouldValidate: true,
                            }
                          );
                        }}
                        selectedOptions={value}
                        color="secondary"
                        type={ButtonGroupTypes.Button}
                        className={'w-full'}
                      />
                    )}
                  />
                </div>
              </div>
            )}

            {haveTheTitleDeeds === true && (
              <ImageInput<ProgrammeDetailsModel>
                acceptedFormats={acceptedFormats}
                label={questions?.[12].question}
                nameProp="r4bPhoto"
                icon="CameraIcon"
                className={'py-4'}
                currentImageString={r4bPhotoUrl}
                register={programmeFormRegister}
                overrideOnClick={() => setPhotoActionBarVisible(true)}
                onValueChange={(imageString: string) => {
                  setProgrammeFormValue('r4bPhoto', imageString);
                  triggerR4bForm();
                }}
                disabled={Boolean(checkedquestion(questions?.[12].question))}
              ></ImageInput>
            )}

            {unproclaimedLand === false && (
              <Alert
                className="mb-4"
                type="warning"
                title={`Apply to your municipality for a copy of the title deeds.`}
                list={[
                  'You will need to get a copy of the title deeds in order to complete this section.',
                  'Once you have the title deeds, answer “Yes” to the Title Deeds question above and add a photo of the document.',
                ]}
              />
            )}

            {unproclaimedLand === true && (
              <>
                <Alert
                  className="mb-4"
                  type="info"
                  title={`Please get a signed, stamped copy of an R4c form instead.`}
                  list={[
                    'This form can be stamped by any commissioner of oaths. This could include: police officers, South African Post Office workers, lawyers, or accountants for example.',
                  ]}
                  button={
                    <Button
                      onClick={onDownloadImageR4c}
                      text="Download the R4c form"
                      icon="DownloadIcon"
                      type={'filled'}
                      color={'primary'}
                      textColor={'white'}
                    />
                  }
                />
                <ImageInput<ProgrammeDetailsModel>
                  acceptedFormats={acceptedFormats}
                  label={questions?.[13].question}
                  nameProp="r4bPhoto"
                  icon="CameraIcon"
                  className={'py-4'}
                  currentImageString={r4bPhotoUrl}
                  register={programmeFormRegister}
                  overrideOnClick={() => setPhotoActionBarVisible(true)}
                  onValueChange={(imageString: string) => {
                    setProgrammeFormValue('r4bPhoto', imageString);
                    triggerR4bForm();
                  }}
                  disabled={Boolean(checkedquestion(questions?.[13].question))}
                ></ImageInput>
              </>
            )}
          </div>
        </div>
        <div>
          <div className="px-4">
            <Button
              type="filled"
              color="primary"
              className={styles.button}
              onClick={() => handleNextSection()}
            >
              {renderIcon('ArrowCircleRightIcon', 'mr-2 text-white w-5')}
              <Typography type={'help'} text={'Next'} color={'white'} />
            </Button>
          </div>
        </div>
      </BannerWrapper>
      {contentConsentTypeEnum && (
        <Article
          consentEnumType={contentConsentTypeEnum}
          visible={presentArticle}
          title={articleTitle}
          onClose={() => setPresentArticle(false)}
          isOpen={true}
        />
      )}
      {/* <Dialog visible={showMap} position={DialogPosition.Bottom} stretch>
        <AddressMap
          onClose={() => setShowMap?.(false)}
          onSubmit={(address) => {
            setProgrammeFormValue('addressLine1', address);
          }}
        />
      </Dialog> */}
      <Dialog
        visible={photoActionBarVisible}
        position={DialogPosition.Bottom}
        stretch
      >
        <div
          className={` ${
            Boolean(checkedquestion(questions?.[11].question))
              ? 'pointer-events-none'
              : ''
          }`}
        >
          <PhotoPrompt
            title={'R4b photo'}
            onClose={() => setPhotoActionBarVisible(false)}
            onAction={(imageUrl: string) => {
              setPhotoUrl(imageUrl);
            }}
            onDelete={() => deleteBirthDocumentPhoto()}
          ></PhotoPrompt>
        </div>
      </Dialog>
    </>
  );
};
