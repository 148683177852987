export type AddNewPractitionerModel = {
  firstName?: string;
  surname?: string;
  idNumber?: string;
  userId?: string;
};

export const AddPractitinerInitialState = {
  firstName: '',
  surname: '',
  idNumber: '',
  userId: '',
};
