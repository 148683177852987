import { ProvinceDto } from '@/../../../packages/core/lib';
import * as Yup from 'yup';

export interface ProgrammeDetailsModel {
  haveReadTheSmartStarterInformation: boolean | undefined;
  programmeName: string;
  programmeType: string;
  ownTheProperty: boolean | undefined;
  liveAtTheProperty?: boolean | undefined;
  haveTheTitleDeeds: boolean | undefined;
  unproclaimedLand: boolean | undefined;
  r4bPhoto: string | undefined;

  // Address Information
  ward: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  provinceId: string;
  postalCode: string;
  areaClassification: string | undefined;
  areaSubClassification: string | undefined;
  area: string | undefined;
  municipality: string | undefined;
}

export const initialProgrammeDetailsValues: ProgrammeDetailsModel = {
  haveReadTheSmartStarterInformation: false,
  programmeName: '',
  ward: '',
  area: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  provinceId: '',
  postalCode: '',
  // province: undefined,
  programmeType: '',
  ownTheProperty: undefined,
  liveAtTheProperty: undefined,
  haveTheTitleDeeds: undefined,
  unproclaimedLand: undefined,
  r4bPhoto: '',
  areaClassification: '',
  areaSubClassification: '',
  municipality: '',
};

export const ProgrammeDetailsSchema = Yup.object().shape({
  haveReadTheSmartStarterInformation: Yup.boolean().required(),
  programmeName: Yup.string().required('Programme name is required'),
  programmeType: Yup.string().required('Programme type is required'),
  ownTheProperty: Yup.boolean().required(
    'Own the property is a required field'
  ),
  haveTheTitleDeeds: Yup.boolean().when('ownTheProperty', {
    is: true,
    then: Yup.boolean(),
  }),
  unproclaimedLand: Yup.boolean().when('haveTheTitleDeeds', {
    is: false,
    then: Yup.boolean(),
  }),
  liveAtTheProperty: Yup.boolean().when('ownTheProperty', {
    is: false,
    then: Yup.boolean(),
  }),
  r4bPhoto: Yup.string().required('Photo is required'),
  addressLine1: Yup.string().required('Address line 1 is required'),
  addressLine2: Yup.string().required('Address line 2 required'),
  addressLine3: Yup.string().required('City is required'),
  provinceId: Yup.string().required('Please select a province'),
  area: Yup.string().required('Area classification is required'),
  areaClassification: Yup.string().required('Area classification is required'),
  areaSubClassification: Yup.string().required(
    'Area sub classification is required'
  ),
  municipality: Yup.string().required('Municipality is required'),
  ward: Yup.string()
    .matches(/^\d{2,4}$/, 'ward must be between 2 and 4 digits')
    .required('ward is required'),
  postalCode: Yup.string()
    .matches(/^\d{4}$/, 'postalCode must be exactly 4 digits')
    .required('postalCode is required'),
});
