import { FileTypeEnum } from '@ecdlink/graphql';
import { SA_CELL_REGEX } from '@ecdlink/ui';
import * as Yup from 'yup';

export interface UserHelpFormModel {
  fileName?: string;
  fileType: FileTypeEnum;
  screenshotBase64String?: string;
  userHelp: HelpFormModel;
}
export interface HelpFormModel {
  Id: string | null;
  InsertedDate: Date;
  UpdatedDate: Date;
  UpdatedBy: string;
  TenantId: string | null;
  UserId: string | null;
  Subject: string;
  Description: string;
  ContactPreference: string;
  ContactPreferenceValue: string;
  CellNumber: string | null;
  Email: string | null;
  IsLoggedIn: boolean;
  Url: string | null;
  PreferSMS: boolean;
}

export type UserHelpImageResponse = {
  Name: string;
  Reference: string;
  Url: string;
};

export const initialHelpFormValues: UserHelpFormModel = {
  fileName: '',
  fileType: FileTypeEnum.Unknown,
  screenshotBase64String: '',
  userHelp: {
    Id: null,
    InsertedDate: new Date(),
    UpdatedDate: new Date(),
    UpdatedBy: '',
    TenantId: null,
    UserId: null,
    Subject: '',
    Description: '',
    ContactPreference: 'SMS',
    CellNumber: null,
    Email: null,
    IsLoggedIn: false,
    Url: null,
    ContactPreferenceValue: '',
    PreferSMS: true,
  },
};

export const HelpValidationSchema = Yup.object().shape({
  Subject: Yup.string().required('Subject is required'),
  Description: Yup.string().required('Description is required'),
  ContactPreferenceValue: Yup.string().when('PreferSMS', {
    is: true,
    then: Yup.string()
      .required('Cellphone number is required')
      .matches(SA_CELL_REGEX, 'Please enter a valid cellphone number'),
    otherwise: Yup.string()
      .required('Email address is required')
      .email('Please enter a valid email address'),
  }),
});
