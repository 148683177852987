import { SectionQuestions } from '@/pages/coach/coach-practitioner-journey/forms/dynamic-form';
import { authSelectors } from '@/store/auth';
import { coachSelectors } from '@/store/coach';
import { traineeSelectors } from '@/store/trainee';
import { SmartSpaceVisitData } from '@/store/trainee/trainee.types';
import { PractitionerDto } from '@ecdlink/core';
import {
  Alert,
  Button,
  CheckboxGroup,
  Colours,
  Divider,
  Typography,
  renderIcon,
  InputField,
} from '@ecdlink/ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

interface SmartSpaceCheck1Props {
  coachSmartSpaceVisitId: string;
  practitioner: PractitionerDto;
  saveSmartSpaceCheckData: (
    value: SmartSpaceVisitData[],
    visitSection: string
  ) => void;
  handleNextSection: () => void;
}

export const getGroupColor = (count: number): Colours => {
  if (count === 0) {
    return 'errorMain';
  }

  if (count < 17) {
    return 'alertMain';
  }

  return 'successMain';
};

export const SmartSpaceCheck8: React.FC<SmartSpaceCheck1Props> = ({
  coachSmartSpaceVisitId,
  practitioner,
  handleNextSection,
  saveSmartSpaceCheckData,
}) => {
  const visitSection = 'Confirm';
  const visitData = useSelector(
    traineeSelectors.getCoachSmartSpaceSectionAnswers(
      coachSmartSpaceVisitId,
      visitSection
    )
  );
  const coach = useSelector(coachSelectors.getCoach);
  const user = useSelector(authSelectors.getAuthUser);
  const isCoach = coach?.user?.id === user?.id;
  const [questions, setAnswers] = useState<SmartSpaceVisitData[]>([
    {
      visitSection,
      question: `I gave ${practitioner?.user?.firstName} a playkit and admin file and explained the contents of the file.`,
      questionAnswer: 'false',
    },
    {
      visitSection,
      question: `Playkit number`,
      questionAnswer: '',
    },
    {
      visitSection,
      question: `I reminded and showed ${practitioner?.user?.firstName} how to keep child attendance (on paper and on Funda App).`,
      questionAnswer: 'false',
    },
    {
      visitSection,
      question: `I have checked that all of ${practitioner?.user?.firstName}'s personal information is correct on the Funda App system.`,
      questionAnswer: 'false',
    },
  ]);
  const trueAnswers = useMemo(() => {
    const filteredQuestions = questions?.filter((item, index) => index !== 1); // Exclude the non-bool question
    const answers = filteredQuestions?.every(
      (item) => item.questionAnswer === 'true'
    );
    return answers;
  }, [questions]);
  const onOptionSelected = useCallback(
    (value: string, index: number) => {
      const currentQuestion = questions[index];
      const updatedQuestions = questions.map((question) => {
        if (question.question === currentQuestion.question) {
          return {
            ...question,
            questionAnswer: value,
          };
        }
        return question;
      });
      setAnswers(updatedQuestions);
    },
    [questions]
  );
  useEffect(() => {
    if (!!visitData && !!visitData.length) {
      setAnswers(visitData);
    }
  }, []);
  const playkitNumberValid = () => {
    var regex = /^\d{5}$/;
    var playkit = questions[1].questionAnswer;
    if (playkit != undefined) return regex.test(playkit);
    else return false;
  };
  return (
    <div className="p-4">
      <Typography
        type={'h2'}
        text={visitSection}
        color={'textDark'}
        className={'my-3'}
      />
      <Divider dividerType="dashed" className={'my-4'} />
      {!isCoach && (
        <Alert
          className="my-4"
          type="warning"
          title="You are viewing this form and cannot fill in responses."
        />
      )}
      <Typography
        type={'h4'}
        text={'Please confirm the following:'}
        color={'textDark'}
        className={'my-3'}
      />
      <CheckboxGroup
        id={questions[0].question}
        key={questions[0].question}
        title={''}
        description={questions[0].question}
        checked={questions?.some(
          (option) =>
            option.question === questions[0].question &&
            option?.questionAnswer === 'true'
        )}
        value={questions[0].questionAnswer}
        onChange={() =>
          onOptionSelected(
            questions[0].questionAnswer === 'true' ? 'false' : 'true',
            0
          )
        }
        className="mb-1"
        disabled={!isCoach}
      />
      {questions[0].questionAnswer != undefined &&
        questions[0].questionAnswer == 'true' && (
          <div
            className="relative flex w-full items-center rounded p-1"
            onClick={(e) => {}}
          >
            <div className="sm:col-span-3">
              <label htmlFor="playkitNumber" className="block text-gray-700">
                Playkit number
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="playkitNumber"
                  placeholder="e.g. 00278"
                  onChange={(e) => {
                    onOptionSelected(e.target.value as string, 1);
                  }}
                  className="focus:ring-primary focus:border-primary mb-2 block w-full rounded-md border-gray-300 text-black shadow-sm sm:text-sm"
                />
              </div>
            </div>
          </div>
        )}
      <CheckboxGroup
        id={questions[2].question}
        key={questions[2].question}
        title={''}
        description={questions[2].question}
        checked={questions?.some(
          (option) =>
            option.question === questions[2].question &&
            option?.questionAnswer === 'true'
        )}
        value={questions[2].questionAnswer}
        onChange={() =>
          onOptionSelected(
            questions[2].questionAnswer === 'true' ? 'false' : 'true',
            2
          )
        }
        className="mb-1"
        disabled={!isCoach}
      />
      <CheckboxGroup
        id={questions[3].question}
        key={questions[3].question}
        title={''}
        description={questions[3].question}
        checked={questions?.some(
          (option) =>
            option.question === questions[3].question &&
            option?.questionAnswer === 'true'
        )}
        value={questions[3].questionAnswer}
        onChange={() =>
          onOptionSelected(
            questions[3].questionAnswer === 'true' ? 'false' : 'true',
            3
          )
        }
        className="mb-1"
        disabled={!isCoach}
      />
      <div className="mt-4 space-y-4">
        <div>
          <div>
            <Button
              type="filled"
              color="primary"
              className="mt-1 mb-2 w-full"
              onClick={() => {
                saveSmartSpaceCheckData(questions, visitSection);
                handleNextSection();
              }}
              disabled={!trueAnswers || !playkitNumberValid() || !isCoach}
            >
              {renderIcon('ArrowCircleRightIcon', 'mr-2 text-white w-5')}
              <Typography type={'help'} text={'Next'} color={'white'} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
