export const wrapper = 'h-full bg-uiBg flex flex-col overflow-auto pb-20';
export const bannerContentWrapper =
  'flex flex-col justify-between items-stretch h-full';
export const spaceTop = 'mt-4';
export const startButton = 'mt-4 mb-4 w-full';
export const categoryArrowIconWrapper =
  'flex flex-row items-center justify-center mt-3';
export const categoryIconWrapper =
  'flex flex-row items-center justify-center mt-6';
export const imageRounder = 'rounded-full p-4';
export const arrowIcon = 'h-6 w-6 text-textMid';
