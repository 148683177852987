export enum ProgressSkillValues {
  Yes = 'Yes',
  TryingToDo = 'Trying to do',
  NotYet = 'Not yet',
}

export const ProgressSkillValuesArray = [
  ProgressSkillValues.Yes,
  ProgressSkillValues.TryingToDo,
  ProgressSkillValues.NotYet,
];
