import ROUTES from '@/routes/routes';
import { Message } from '@models/messages/messages';

interface NotificationTagConfigParams {
  [key: string]: Partial<Message>;
  AcceptAgreement: Partial<Message>;
  GetStartedTrainee: Partial<Message>;
  StartJourney: Partial<Message>;
  AddedToProgramme: Partial<Message>;
  SayHello: Partial<Message>;
  UpdateFee: Partial<Message>;
  LearnMore: Partial<Message>;
  PrincipalAgreement: Partial<Message>;
  NewClass: Partial<Message>;
}
export const notificationTagConfig: NotificationTagConfigParams = {
  AcceptAgreement: {
    cta: 'AcceptAgreement',
    routeConfig: {
      route: ROUTES.PRACTITIONER.COMMUNITY.ACCEPT_CLUB_LEADER_ROLE,
    },
    viewType: 'Both',
  },
  GetStartedTrainee: {
    cta: 'GetStartedTrainee',
    routeConfig: {
      route: ROUTES.TRAINEE.TRAINEE_ONBOARDING,
    },
    viewType: 'Both',
  },
  StartJourney: {
    cta: 'StartJourney',
    routeConfig: {
      route: ROUTES.TRAINEE.TRAINEE_ONBOARDING,
    },
    viewType: 'Both',
  },
  AddedToProgramme: {
    cta: '[[EditProfile]]',
    routeConfig: {
      route: ROUTES.PRACTITIONER.PROFILE.EDIT,
    },
    viewType: 'Messages',
  },
  SayHello: {
    cta: '[[SayHello]]',
    routeConfig: {
      route: ROUTES.PRACTITIONER.COMMUNITY.WELCOME,
    },
    viewType: 'Both',
  },
  UpdateFee: {
    cta: '[[UpdateFee]]',
    routeConfig: {
      route: ROUTES.CLASSROOM.UPDATE_FEE,
    },
    viewType: 'Both',
  },
  LearnMore: {
    cta: '[[LearnMore]]',
    routeConfig: {
      route: ROUTES.TRAINEE.TRAINEE_ONBOARDING,
    },
    viewType: 'Both',
  },
  PrincipalAgreement: {
    cta: '[[PrincipalAgreement]]',
    viewType: 'Both',
  },
  NewClass: {
    cta: '[[SeeNewClass]]',
    viewType: 'Both',
  },
};

export const enum MessageStatusConstants {
  Amber = 'amber',
  Blue = 'blue',
  Red = 'red',
  Green = 'green',
}
